<template>
  <component
    :is="componentTag"
    :href="sold ? null : vehicleUrl"
    class="am-vehicle-tile-extended"
    :class="{
      'am-vehicle-tile-extended--favored': isFavorite
    }"
    @click.stop.prevent="onViewVehicle"
  >
    <div class="am-vehicle-tile-extended__inner">
      <div>
        <button
          class="am-vehicle-tile-extended__fav-button"
          :aria-label="
            isFavorite
              ? $t('favorites.vehicle.remove')
              : $t('favorites.vehicle.add')
          "
          @click.stop.prevent="$emit('favour')"
        >
          <amui-icon :name="favoriteIconName"></amui-icon>
        </button>
        <div class="am-vehicle-tile-extended__subtitle">
          {{ make }} {{ amModelYear }}
        </div>
        <div class="am-vehicle-tile-extended__title">{{ model }}</div>
        <div class="am-vehicle-tile-extended__chips">
          <amui-flex-chip
            v-if="usageType !== null"
            :label="usageType.label"
            class="am-vehicle-tile-extended__chip-usage-type"
            :class="{
              'am-vehicle-tile-extended__chip-usage-type--primary':
                usageType.value === 'NEW'
            }"
          />
          <amui-flex-chip
            v-if="!sold && availabilityInWeeks === 0"
            :label="availabilityLabel"
            class="am-vehicle-tile-extended__chip-availability"
          />
          <div
            v-if="
              !sold && availabilityInWeeks !== null && availabilityInWeeks > 0
            "
            class="am-vehicle-tile-extended__availability-calendar"
          >
            <amui-icon name="calendar-today" />
            <span>{{ availabilityLabel }}</span>
          </div>
          <amui-flex-chip
            v-if="sold"
            label="Verkauft"
            class="am-vehicle-tile-extended__chip-sold"
          />
        </div>
        <div
          v-if="sold"
          class="am-vehicle-tile-extended__sold-image-placeholder"
        >
          <div class="am-vehicle-tile-extended__sold-image-placeholder-content">
            <amui-icon name="not-interested"></amui-icon>
            <p>Verkauft</p>
          </div>
        </div>
        <div v-else class="am-vehicle-tile-extended__image-wrap">
          <amui-lazy-image
            :source="imageSource"
            :alt="imageAlt"
            :fetch-priority="lcpImageSource ? 'high' : null"
            class="am-vehicle-tile-extended__image"
            @change="onChangeImageWidth"
          />
        </div>
        <div
          v-if="exterior || interior"
          class="am-vehicle-tile-extended__colors"
        >
          <div v-if="exterior" class="am-vehicle-tile-extended__feature">
            <amui-icon
              :name="exterior.icon"
              :class="{
                'am-vehicle-tile-extended__feature-icon': true,
                ['am-vehicle-tile-extended__feature-icon--' +
                (exterior.iconColor || '').toLowerCase()]: exterior.iconColor
              }"
            />{{ exterior.label }}
          </div>
          <div v-if="interior" class="am-vehicle-tile-extended__feature">
            <amui-icon
              :name="interior.icon"
              :class="{
                'am-vehicle-tile-extended__feature-icon': true,
                ['am-vehicle-tile-extended__feature-icon--' +
                (interior.iconColor || '').toLowerCase()]: interior.iconColor
              }"
            />{{ interior.label }}
          </div>
        </div>
        <div class="am-vehicle-tile-extended__features">
          <div
            v-for="(feature, index) in computedFeatures"
            :key="feature.label + index"
            class="am-vehicle-tile-extended__feature"
          >
            <amui-icon
              :name="feature.icon"
              class="am-vehicle-tile-extended__feature-icon"
            />{{ feature.label }}
          </div>
        </div>
        <div
          v-if="customerHighlights.length"
          class="am-vehicle-tile-extended__highlights"
        >
          {{ customerHighlights.map(c => c.label).join(', ') }}
        </div>
      </div>
      <div>
        <div v-if="price" class="am-vehicle-tile-extended__prices">
          <div class="am-vehicle-tile-extended__price-row-top">
            <template v-if="crossedOutPrice">
              <span>{{ crossedOutPrice }}</span>
              <sup> {{ crossedOutPriceReferenceNumber }}</sup>
            </template>
          </div>
          <div class="am-vehicle-tile-extended__price-row">
            <div
              class="am-vehicle-tile-extended__purchase-price"
              :class="{
                'am-vehicle-tile-extended__purchase-price--highlighted': crossedOutPrice
              }"
            >
              {{ price }}
            </div>
            <div v-if="rate" class="am-vehicle-tile-extended__monthly-price">
              {{ rate }}
            </div>
          </div>
          <div class="am-vehicle-tile-extended__price-row-bottom">
            <div>{{ priceTaxInfo }}</div>
            <div v-if="rate">Rate/Monat</div>
          </div>
        </div>
        <slot name="integration"></slot>
        <div
          v-if="!isMotorbike && (!sold || efficiencyText)"
          class="am-vehicle-tile-extended__efficiency"
        >
          <amui-efficiency-class-icon
            v-if="efficiencyClass && efficiencyText"
            v-model="efficiencyClass"
            small
          />
          <span>{{ efficiencyText }}<sup v-if="efficiencyText">1</sup></span>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiFlexChip } from '@/../ui/components/flex-chip'
import { AmuiLazyImage } from '@/../ui/components/lazy-image'
import { AmuiEfficiencyClassIcon } from '@/../ui/components/efficiency-class-icon'

export default {
  name: 'AmVehicleTileExtended',

  components: {
    AmuiIcon,
    AmuiFlexChip,
    AmuiLazyImage,
    AmuiEfficiencyClassIcon
  },

  props: {
    vehicleUrl: {
      type: String,
      required: true
    },
    sold: {
      type: Boolean,
      required: false,
      default: false
    },
    // raw image sources
    media: {
      type: Array,
      required: false,
      default: () => []
    },
    // if image is the lcp element, then it should have an image tag in markup
    lcpImageSource: {
      type: String,
      required: false,
      default: null
    },
    title: {
      // used as image alt text
      type: String,
      required: true
    },
    price: {
      type: String,
      required: true
    },
    make: {
      type: String,
      required: true
    },
    amModelYear: {
      type: Number,
      required: false,
      default: null
    },
    model: {
      type: String,
      required: true
    },
    vehicleClass: {
      type: String,
      required: true
    },
    isFavorite: {
      type: Boolean,
      required: false,
      default: false
    },
    rate: {
      type: String,
      required: false,
      default: null
    },
    usageType: {
      type: Object,
      required: false,
      default: null
    },
    availabilityInWeeks: {
      type: Number,
      required: false,
      default: null
    },
    availabilityLabel: {
      type: String,
      required: false,
      default: null
    },
    efficiencyClass: {
      type: String,
      required: false,
      default: null
    },
    efficiencyText: {
      type: String,
      default: null
    },
    customerHighlights: {
      type: Array,
      required: false,
      default: () => []
    },
    features: {
      type: Array,
      required: false,
      default: null
    },
    crossedOutPrice: {
      type: String,
      required: false,
      default: null
    },
    crossedOutPriceReferenceNumber: {
      type: Number,
      required: false,
      default: null
    },
    priceTaxInfo: {
      required: false,
      default: null
    }
  },

  data() {
    return {
      imageWidth: null,
      imageSource: null,
      imageAlt: null
    }
  },

  created() {
    this.imageSource = this.lcpImageSource || null
  },

  computed: {
    componentTag() {
      return this.sold ? 'div' : 'a'
    },
    favoriteIconName() {
      return this.isFavorite ? 'favorite-filled' : 'favorite'
    },
    isMotorbike() {
      return this.vehicleClass === 'Motorbike'
    },
    exterior() {
      return this.features.find(feature => feature.id === 'exterior')
    },
    interior() {
      return this.features.find(feature => feature.id === 'interior')
    },
    computedFeatures() {
      return this.features.filter(
        feature => !['exterior', 'interior'].includes(feature.id)
      )
    }
  },

  methods: {
    onViewVehicle() {
      if (!this.sold) {
        this.$emit('view')
      }
    },
    onChangeImageWidth(width) {
      const media = this.media.length ? this.media[0] : null
      const computedWidth = width * (window.devicePixelRatio || 1)

      if (media) {
        if (media.type === 'cpo') {
          const splittedSegments = media.normal.split('/')
          const filename = splittedSegments.pop()
          this.imageSource =
            splittedSegments.join('/') +
            '/tr:w-' +
            computedWidth +
            '/' +
            filename
          this.imageAlt = media.alt
        } else {
          this.imageSource = media.normal
          this.imageAlt = this.title
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import './vehicle-tile-extended';
</style>
