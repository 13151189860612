import { facetVehiclesByCompany } from '@/app/requests/vehicles'

export default {
  data() {
    return {
      availableCategories: [],
      selectableCategories: [],

      availableAmUsageTypes: [],
      selectableAmUsageTypes: [],

      availableMakes: [],
      selectableMakes: [],

      availableModelGroups: [],
      selectableModelGroups: [],

      availableModels: [],
      selectableModels: [],

      availableFuels: [],
      selectableFuels: [],

      availableUsedCarSeals: [],
      selectableUsedCarSeals: []
    }
  },

  computed: {
    showResetLink() {
      const initialParams = this.getInitialSearchParams()

      const omit = (key, obj) => {
        // eslint-disable-next-line no-unused-vars
        const { [key]: omitted, ...rest } = obj
        return rest
      }

      return (
        JSON.stringify(omit('vehicleClass', this.params)) !==
        JSON.stringify(omit('vehicleClass', initialParams))
      )
    },
    categoryOptions() {
      // api ids to available component icon name mapping
      const categoryMappingByVehicleClass = {
        Car: {
          Cabrio: 'car-cabriolet',
          SportsCar: 'car-sports-car',
          SmallCar: 'car-small-car',
          OffRoad: 'car-suv',
          EstateCar: 'car-estate',
          Van: 'car-van',
          Limousine: 'car-saloon',
          OtherCar: 'car-other'
        },
        Motorbike: {
          ChopperAndCruiser: 'motorbike-chopper',
          CombinationAndSidecar: 'motorbike-combination',
          DirtBike: 'motorbike-dirt-bike',
          EnduroAndTouringEnduro: 'motorbike-enduro',
          LightweightMotorcycleAndMotorbike: 'motorbike-lightweight',
          MotorAssistedBicycleAndSmallMoped: 'motorbike-small-moped',
          Motorcycle: 'motorbike-motorcycle',
          NakedBike: 'motorbike-naked-bike',
          Pocketbike: 'motorbike-pocket-bike',
          Quad: 'motorbike-quad',
          Racing: 'motorbike-racing',
          RallyAndCross: 'motorbike-rally-cross',
          Scooter: 'motorbike-scooter',
          SportTouringMotorcycle: 'motorbike-sport-touring',
          SportsAndSuperSportsBike: 'motorbike-sports-bike',
          Streetfighter: 'motorbike-streetfighter',
          SuperMoto: 'motorbike-super-moto',
          Tourer: 'motorbike-tourer',
          Trike: 'motorbike-trike',
          OtherMotorbike: 'motorbike-other'
        }
      }

      const categoryMapping =
        categoryMappingByVehicleClass[this.vehicleClassModel]

      return Object.keys(categoryMapping)
        .filter(
          key => this.availableCategories.find(c => c._id === key) !== undefined
        )
        .map(key => {
          const availableCategory = this.availableCategories.find(
            c => c._id === key
          )
          const selectableCategory = this.selectableCategories.find(
            c => c._id === key
          )
          return {
            icon: categoryMapping[key],
            label:
              this.$t(
                'vehicleClass.' +
                  this.vehicleClassModel +
                  '.category' +
                  availableCategory._id
              ) +
              (selectableCategory ? ' (' + selectableCategory.count + ')' : ''),
            id: availableCategory._id,
            disabled:
              this.selectableCategories.find(
                c => c._id === availableCategory._id
              ) === undefined
          }
        })
    },
    amUsageTypeOptions() {
      return this.availableAmUsageTypes
        .filter(o => o._id !== null)
        .map(availableAmUsageType => {
          return {
            label: this.$t(
              'vehicleClass.' +
                this.vehicleClassModel +
                '.amUsageType' +
                availableAmUsageType._id
            ),
            alternateLabel: this.$t(
              'vehicleClass.' +
                this.vehicleClassModel +
                '.amUsageType' +
                availableAmUsageType._id +
                '-alternate'
            ),
            value: availableAmUsageType._id,
            disabled:
              this.selectableAmUsageTypes.find(
                u => u._id === availableAmUsageType._id
              ) === undefined
          }
        })
    },
    makeOptions() {
      const options = this.availableMakes
        .map(item => {
          const selectableMake = this.selectableMakes.find(
            c => c._id === item._id
          )
          return {
            label: `${this.$t('vehicle.make' + item._id)}  ${
              selectableMake ? '(' + selectableMake.count + ')' : ''
            }`,
            value: item._id,
            disabled:
              this.selectableMakes.find(m => m._id === item._id) === undefined
          }
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1))
      const value = this.params.make
      if (value.length > 0) {
        options.unshift({
          label: this.$t('vehicle.reset'),
          value: ''
        })
      }
      return options
    },

    modelGroupOptions() {
      const options = this.availableModelGroups
        .map(item => {
          const selectableModelGroup = this.selectableModelGroups.find(
            c => c._id === item._id
          )
          return {
            label: `${item._id}  ${
              selectableModelGroup ? '(' + selectableModelGroup.count + ')' : ''
            }`,
            value: item._id,
            disabled:
              this.selectableModelGroups.find(m => m._id === item._id) ===
              undefined
          }
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1))
      const value = this.params.modelGroup
      if (value.length > 0) {
        options.unshift({
          label: this.$t('vehicle.reset'),
          value: null
        })
      }
      return options
    },

    modelOptions() {
      const options = this.availableModels
        .map(item => {
          const selectableModel = this.selectableModels.find(
            c => c._id === item._id
          )
          return {
            label: `${item._id}  ${
              selectableModel ? '(' + selectableModel.count + ')' : ''
            }`,
            value: item._id
          }
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1))
      const value = this.params.model
      if (value.length > 0) {
        options.unshift({
          label: this.$t('vehicle.reset'),
          value: null
        })
      }
      return options
    },

    fuelOptions() {
      return this.availableFuels
        .map(availableFuel => {
          return {
            label: this.$t('vehicle.fuel' + availableFuel._id),
            value: availableFuel._id,
            disabled:
              this.selectableFuels.find(f => f._id === availableFuel._id) ===
              undefined
          }
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1))
    },

    usedCarSealOptions() {
      return this.availableUsedCarSeals
        .filter(item => item._id !== null)
        .map(item => {
          return {
            label: this.$t('vehicle.usedCarSeal' + item._id),
            value: item._id,
            disabled:
              this.selectableUsedCarSeals.find(c => c._id === item._id) ===
              undefined
          }
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1))
    }
  },

  methods: {
    _facetVehicleSearch(params) {
      const _params = Object.assign(
        {
          flags: [],
          vehicleClass: this.params.vehicleClass
        },
        params
      )
      return facetVehiclesByCompany(this.companyId, _params)
    },
    initFacetVehicleSearch() {
      return this.fetchAvailableValues().then(async () => {
        const params = Object.assign(
          {},
          await this.fetchSelectableCategories(this.params),
          await this.fetchSelectableAmUsageTypes(this.params),
          await this.fetchSelectableMakes(this.params),
          await this.fetchSelectableModelGroups(this.params),
          await this.fetchSelectableModels(this.params),
          await this.fetchSelectableFuels(this.params),
          await this.fetchSelectableUsedCarSeals(this.params)
        )

        // To prevent that params will be set each time and a change event will be triggered on inital request
        if (
          JSON.stringify(Object.assign({}, this.params, params)) !==
          JSON.stringify(this.params)
        ) {
          this.params = Object.assign({}, this.params, params)
        }
      })
    },
    fetchAvailableValues() {
      return this._facetVehicleSearch()
        .then(res => {
          this.availableCategories = res.categorizedByBodyType
          this.availableAmUsageTypes = res.categorizedByAmUsageType
          this.availableMakes = res.categorizedByMake
          this.availableFuels = res.categorizedByFuel
          this.availableUsedCarSeals = res.categorizedByUsedCarSeal

          if (this.params.make.length) {
            this.fetchAvailableModelGroups(this.params.make)

            if (this.params.modelGroup.length) {
              this.fetchAvailableModels(
                this.params.make,
                this.params.modelGroup
              )
            }
          }
        })
        .catch(err => {
          // TODO: error handling
          console.log('error handling missing', err)
        })
    },

    defaultOption(value = '') {
      return {
        label: this.$t('vehicle.reset'),
        value
      }
    },

    facetVehicleSearch() {
      // TODO: rename to fetchTotalCount?
      const params = this.getGraphQLParamsByStore()
      return this._facetVehicleSearch(params)
        .then(res => {
          this.setTotal(res)
        })
        .catch(err => {
          // TODO: error handling
          console.log('error handling missing', err)
        })
    },

    async onChangeCategory(selectedCategories) {
      const selectedDisabledOption = selectedCategories.find(
        selectedCategoryId => {
          return this.categoryOptions.find(
            categoryOption =>
              categoryOption.id === selectedCategoryId &&
              categoryOption.disabled
          )
        }
      )

      let params = {}

      if (selectedDisabledOption !== undefined) {
        this.resetFacetSearchSelectables()
        params = this.getInitialSearchParamsWithCurrentVehicleClass()
        params.category = [selectedDisabledOption]
      } else {
        params.category = selectedCategories
      }

      params = Object.assign({}, this.params, params)

      this.params = Object.assign(
        {},
        params,
        await this.fetchSelectableAmUsageTypes(params),
        await this.fetchSelectableMakes(params),
        await this.fetchSelectableModelGroups(params),
        await this.fetchSelectableModels(params),
        await this.fetchSelectableFuels(params),
        await this.fetchSelectableUsedCarSeals(params)
      )
    },

    async onChangeAmUsageType(selectedAmUsageTypes) {
      const selectedDisabledOption = selectedAmUsageTypes.find(
        selectedAmUsageTypeId => {
          return this.amUsageTypeOptions.find(
            amUsageTypeOption =>
              amUsageTypeOption.value === selectedAmUsageTypeId &&
              amUsageTypeOption.disabled
          )
        }
      )

      let params = {}

      if (selectedDisabledOption !== undefined) {
        this.resetFacetSearchSelectables()
        params = this.getInitialSearchParamsWithCurrentVehicleClass()
        params.amUsageType = [selectedDisabledOption]
      } else {
        params.amUsageType = selectedAmUsageTypes
      }

      params = Object.assign({}, this.params, params)

      this.params = Object.assign(
        {},
        params,
        await this.fetchSelectableCategories(params),
        await this.fetchSelectableMakes(params),
        await this.fetchSelectableModelGroups(params),
        await this.fetchSelectableModels(params),
        await this.fetchSelectableFuels(params),
        await this.fetchSelectableUsedCarSeals(params)
      )
    },

    async onChangeMake(make) {
      const hasSelectedDisabledOption =
        this.selectableMakes.find(m => m._id === make) === undefined

      let params = {}

      if (hasSelectedDisabledOption && make !== null) {
        this.resetFacetSearchSelectables()
        params = this.getInitialSearchParamsWithCurrentVehicleClass()
        params.make = [make]
      } else {
        params.make = make !== null ? [make] : []
        params.modelGroup = []
        params.model = []
        this.selectableModels = []
      }

      await this.fetchAvailableModelGroups(params.make)

      params = Object.assign({}, this.params, params)

      this.params = Object.assign(
        {},
        params,
        await this.fetchSelectableCategories(params),
        await this.fetchSelectableAmUsageTypes(params),
        await this.fetchSelectableModelGroups(params),
        await this.fetchSelectableFuels(params),
        await this.fetchSelectableUsedCarSeals(params)
      )
    },

    async onChangeModelGroup(modelGroup) {
      const hasSelectedDisabledOption =
        this.selectableModelGroups.find(m => m._id === modelGroup) === undefined

      let params = {}

      if (hasSelectedDisabledOption && modelGroup !== null) {
        const make = this.params.make
        this.resetFacetSearchSelectables()
        params = this.getInitialSearchParamsWithCurrentVehicleClass()
        params.make = make
        params.modelGroup = [modelGroup]
      } else {
        params.modelGroup = modelGroup !== null ? [modelGroup] : []
        params.model = []
        this.selectableModels = []
      }

      await this.fetchSelectableModelGroups(
        Object.assign({}, this.params, params)
      )
      await this.fetchAvailableModels(this.params.make, params.modelGroup)

      params = Object.assign({}, this.params, params)

      this.params = Object.assign(
        {},
        params,
        await this.fetchSelectableCategories(params),
        await this.fetchSelectableAmUsageTypes(params),
        await this.fetchSelectableModels(params),
        await this.fetchSelectableFuels(params),
        await this.fetchSelectableUsedCarSeals(params)
      )
    },

    async onChangeModel(model) {
      let params = {
        model: model !== null ? [model] : []
      }

      params = Object.assign({}, this.params, params)

      this.params = Object.assign(
        {},
        params,
        await this.fetchSelectableCategories(params),
        await this.fetchSelectableAmUsageTypes(params),
        await this.fetchSelectableFuels(params),
        await this.fetchSelectableUsedCarSeals(params)
      )
    },

    async onChangeFuel(selectedFuels) {
      const selectedDisabledOption = selectedFuels.find(selectedFuelId => {
        return this.fuelOptions.find(
          fuelOption =>
            fuelOption.value === selectedFuelId && fuelOption.disabled
        )
      })

      let params = {}

      if (selectedDisabledOption !== undefined) {
        this.resetFacetSearchSelectables()
        params = this.getInitialSearchParamsWithCurrentVehicleClass()
        params.fuel = [selectedDisabledOption]
      } else {
        params.fuel = selectedFuels
      }

      params = Object.assign({}, this.params, params)

      this.params = Object.assign(
        {},
        params,
        await this.fetchSelectableCategories(params),
        await this.fetchSelectableAmUsageTypes(params),
        await this.fetchSelectableMakes(params),
        await this.fetchSelectableModelGroups(params),
        await this.fetchSelectableModels(params),
        await this.fetchSelectableUsedCarSeals(params)
      )
    },

    async onChangeUsedCarSeal(selectedUsedCarSeals) {
      let validateSelection = true
      const selectedDisabledOption = selectedUsedCarSeals.find(
        selectedUsedCarSealId => {
          return this.usedCarSealOptions.find(
            usedCarSealOption =>
              usedCarSealOption.value === selectedUsedCarSealId &&
              usedCarSealOption.disabled
          )
        }
      )

      let params = {}

      if (selectedDisabledOption !== undefined) {
        validateSelection = false

        this.resetFacetSearchSelectables()
        params = this.getInitialSearchParamsWithCurrentVehicleClass()

        params.usedCarSeal = [selectedDisabledOption]

        // set amUsageType because the usedCarSeal depends on usage type in UI
        await this.fetchSelectableAmUsageTypes(params, false) // this will implizit set this.selectableAmUsageTypes
        this.selectableUsedCarSeals = this.availableUsedCarSeals
        params.amUsageType = this.selectableAmUsageTypes.map(s => s._id)
      } else {
        params.usedCarSeal = selectedUsedCarSeals
      }

      params = Object.assign({}, this.params, params)

      this.params = Object.assign(
        {},
        params,
        await this.fetchSelectableCategories(params),
        await this.fetchSelectableAmUsageTypes(params, validateSelection),
        await this.fetchSelectableMakes(params),
        await this.fetchSelectableModelGroups(params),
        await this.fetchSelectableModels(params),
        await this.fetchSelectableFuels(params)
      )
    },

    /**
     * returns an empty object or a filtered object if some of the selectable values has changed.
     */
    setSelectableCategories(categorizedByBodyType) {
      const retParams = {}

      const filteredCategory = this.params.category.filter(
        categoryId =>
          categorizedByBodyType.find(d => d._id === categoryId) !== undefined
      )

      if (
        JSON.stringify(this.params.category) !==
        JSON.stringify(filteredCategory)
      ) {
        retParams.category = filteredCategory
      }

      this.selectableCategories = categorizedByBodyType

      return retParams
    },

    /**
     * returns an empty object or a filtered object if some of the selectable values has changed.
     */
    setSelectableAmUsageTypes(
      categorizedByAmUsageType,
      validateSelection = true
    ) {
      const retParams = {}

      const filteredAmUsageType = this.params.amUsageType.filter(
        amUsageTypeId =>
          validateSelection === false ||
          categorizedByAmUsageType.find(d => d._id === amUsageTypeId) !==
            undefined
      )

      if (
        JSON.stringify(this.params.amUsageType) !==
        JSON.stringify(filteredAmUsageType)
      ) {
        retParams.amUsageType = filteredAmUsageType
      }

      this.selectableAmUsageTypes = categorizedByAmUsageType

      return retParams
    },

    /**
     * returns an empty object or a filtered object if some of the selectable values has changed.
     */
    setSelectableMakes(categorizedByMake) {
      const retParams = {}

      if (
        categorizedByMake.find(m => this.params.make.includes(m._id)) ===
        undefined
      ) {
        retParams.make = []
      }

      this.selectableMakes = categorizedByMake

      return retParams
    },

    /**
     * returns an empty object or a filtered object if some of the selectable values has changed.
     */
    setSelectableModelGroups(categorizedBySeries) {
      const retParams = {}

      if (
        categorizedBySeries.find(m =>
          this.params.modelGroup.includes(m._id)
        ) === undefined
      ) {
        retParams.modelGroup = []
      }

      this.selectableModelGroups = categorizedBySeries

      return retParams
    },

    /**
     * returns an empty object or a filtered object if some of the selectable values has changed.
     */
    setSelectableModels(categorizedByModel) {
      const retParams = {}

      if (
        categorizedByModel.find(m => this.params.model.includes(m._id)) ===
        undefined
      ) {
        retParams.model = []
      }

      this.selectableModels = categorizedByModel

      return retParams
    },

    /**
     * returns an empty object or a filtered object if some of the selectable values has changed.
     */
    setSelectableFuels(categorizedByFuel) {
      const retParams = {}

      const filteredFuelParams = this.params.fuel.filter(
        fuelId => categorizedByFuel.find(f => f._id === fuelId) !== undefined
      )

      if (filteredFuelParams.length !== this.params.fuel.length) {
        retParams.fuel = filteredFuelParams
      }

      this.selectableFuels = categorizedByFuel

      return retParams
    },

    /**
     * returns an empty object or a filtered object if some of the selectable values has changed.
     */
    setSelectableUsedCarSeals(categorizedByUsedCarSeal) {
      const retParams = {}

      const filteredUsedCarSeal = this.params.usedCarSeal.filter(
        usedCarSealId =>
          categorizedByUsedCarSeal.find(d => d._id === usedCarSealId) !==
          undefined
      )

      if (
        JSON.stringify(this.params.usedCarSeal) !==
        JSON.stringify(filteredUsedCarSeal)
      ) {
        retParams.usedCarSeal = filteredUsedCarSeal
      }

      this.selectableUsedCarSeals = categorizedByUsedCarSeal

      return retParams
    },

    resetModelSelection() {
      this.selectableModels = []
    },

    resetFacetSearchSelectables() {
      this.resetBudgetParams && this.resetBudgetParams()
      this.selectableCategories = this.availableCategories
      this.selectableAmUsageTypes = this.availableAmUsageTypes
      this.selectableMakes = this.availableMakes
      this.selectableModelGroups = []
      this.selectableModels = []
      this.selectableFuels = this.availableFuels
      this.selectableUsedCarSeals = this.availableUsedCarSeals
    },

    async fetchSelectableCategories(_params) {
      const params = {}

      if (_params.amUsageType.length) {
        params.amUsageType = _params.amUsageType
      }

      if (_params.make.length) {
        params.make = _params.make
      }

      if (_params.modelGroup.length) {
        params.modelGroup = _params.modelGroup
      }

      if (_params.model.length) {
        params.model = _params.model
      }

      if (_params.fuel.length) {
        params.fuel = _params.fuel
      }

      if (_params.usedCarSeal.length) {
        params.usedCarSeal = _params.usedCarSeal
      }

      if (Object.keys(params).length > 0) {
        return this._facetVehicleSearch(params)
          .then(res => {
            return this.setSelectableCategories(res.categorizedByBodyType)
          })
          .catch(err => {
            // TODO: error handling
            console.log('error handling missing', err)
            return {}
          })
      } else {
        this.selectableCategories = this.availableCategories
        return {}
      }
    },

    async fetchSelectableAmUsageTypes(_params, validateSelection = true) {
      const params = {}

      if (_params.category.length) {
        params.category = _params.category
      }

      if (_params.make.length) {
        params.make = _params.make
      }

      if (_params.modelGroup.length) {
        params.modelGroup = _params.modelGroup
      }

      if (_params.model.length) {
        params.model = _params.model
      }

      if (_params.fuel.length) {
        params.fuel = _params.fuel
      }

      if (_params.usedCarSeal.length) {
        params.usedCarSeal = _params.usedCarSeal
      }

      if (Object.keys(params).length > 0) {
        return this._facetVehicleSearch(params)
          .then(res => {
            return this.setSelectableAmUsageTypes(
              res.categorizedByAmUsageType,
              validateSelection
            )
          })
          .catch(err => {
            // TODO: error handling
            console.log('error handling missing', err)
            return {}
          })
      } else {
        this.selectableAmUsageTypes = this.availableAmUsageTypes
        return {}
      }
    },

    async fetchSelectableMakes(_params) {
      const params = {}

      if (_params.category.length) {
        params.category = _params.category
      }

      if (_params.amUsageType.length) {
        params.amUsageType = _params.amUsageType
      }

      if (_params.fuel.length) {
        params.fuel = _params.fuel
      }

      if (_params.usedCarSeal.length) {
        params.usedCarSeal = _params.usedCarSeal
      }

      if (Object.keys(params).length > 0) {
        return this._facetVehicleSearch(params)
          .then(res => {
            return this.setSelectableMakes(res.categorizedByMake)
          })
          .catch(err => {
            // TODO: error handling
            console.log('error handling missing', err)
            return {}
          })
      } else {
        this.selectableMakes = this.availableMakes
        return {}
      }
    },

    async fetchSelectableModelGroups(_params) {
      if (_params.make.length) {
        const params = {
          make: _params.make
        }

        if (_params.category.length) {
          params.category = _params.category
        }

        if (_params.amUsageType.length) {
          params.amUsageType = _params.amUsageType
        }

        if (_params.fuel.length) {
          params.fuel = _params.fuel
        }

        if (_params.usedCarSeal.length) {
          params.usedCarSeal = _params.usedCarSeal
        }

        return this._facetVehicleSearch(params)
          .then(res => {
            return this.setSelectableModelGroups(res.categorizedBySeries)
          })
          .catch(err => {
            // TODO: error handling
            console.log('error handling missing', err)
            return {}
          })
      } else {
        this.selectableModelGroups = this.availableModelGroups
        return {}
      }
    },

    async fetchSelectableModels(_params) {
      if (_params.make.length && _params.modelGroup.length) {
        const params = {
          make: _params.make,
          modelGroup: _params.modelGroup
        }

        if (_params.category.length) {
          params.category = _params.category
        }

        if (_params.amUsageType.length) {
          params.amUsageType = _params.amUsageType
        }

        if (_params.usedCarSeal.length) {
          params.usedCarSeal = _params.usedCarSeal
        }

        if (_params.fuel.length) {
          params.fuel = _params.fuel
        }

        return this._facetVehicleSearch(params)
          .then(res => {
            return this.setSelectableModels(res.categorizedByModel)
          })
          .catch(err => {
            // TODO: error handling
            console.log('error handling missing', err)
            return {}
          })
      } else {
        this.selectableModels = this.availableModels
        return {}
      }
    },

    async fetchSelectableFuels(_params) {
      const params = {}

      if (_params.category.length) {
        params.category = _params.category
      }

      if (_params.amUsageType.length) {
        params.amUsageType = _params.amUsageType
      }

      if (_params.make.length) {
        params.make = _params.make
      }

      if (_params.modelGroup.length) {
        params.modelGroup = _params.modelGroup
      }

      if (_params.model.length) {
        params.model = _params.model
      }

      if (_params.usedCarSeal.length) {
        params.usedCarSeal = _params.usedCarSeal
      }

      if (Object.keys(params).length > 0) {
        return this._facetVehicleSearch(params)
          .then(res => {
            return this.setSelectableFuels(res.categorizedByFuel)
          })
          .catch(err => {
            // TODO: error handling
            console.log('error handling missing', err)
            return {}
          })
      } else {
        this.selectableFuels = this.availableFuels
        return {}
      }
    },

    fetchSelectableUsedCarSeals(_params) {
      const params = {}

      if (_params.category.length) {
        params.category = _params.category
      }

      if (_params.amUsageType.length) {
        params.amUsageType = _params.amUsageType
      }

      if (_params.make.length) {
        params.make = _params.make
      }

      if (_params.modelGroup.length) {
        params.modelGroup = _params.modelGroup
      }

      if (_params.model.length) {
        params.model = _params.model
      }

      if (_params.fuel.length) {
        params.fuel = _params.fuel
      }

      if (Object.keys(params).length > 0) {
        return this._facetVehicleSearch(params)
          .then(res => {
            return this.setSelectableUsedCarSeals(res.categorizedByUsedCarSeal)
          })
          .catch(err => {
            // TODO: error handling
            console.log('error handling missing', err)
            return {}
          })
      } else {
        this.selectableUsedCarSeals = this.availableUsedCarSeals
        return {}
      }
    },

    fetchAvailableModelGroups(make) {
      return this._facetVehicleSearch({ make })
        .then(res => {
          this.availableModelGroups = res.categorizedBySeries
        })
        .catch(err => {
          // TODO: error handling
          console.log('error handling missing', err)
        })
    },

    fetchAvailableModels(make, modelGroup) {
      return this._facetVehicleSearch({ make, modelGroup })
        .then(res => {
          this.availableModels = res.categorizedByModel
        })
        .catch(err => {
          // TODO: error handling
          console.log('error handling missing', err)
        })
    }
  }
}
