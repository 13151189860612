<template>
  <div class="am-search-hybrid-sidebar">
    <div class="am-search-hybrid-sidebar__content">
      <div class="am-search-hybrid-sidebar__content-inner">
        <div class="am-search-hybrid-sidebar__header">
          <amui-icon
            name="arrow-back"
            class="am-search-hybrid-sidebar__header-icon"
            @click.native="$emit('close')"
          />
          <span class="am-search-hybrid-sidebar__header-title">Suche</span>
        </div>
        <div class="am-search-hybrid-sidebar__vehicle-class-wrapper">
          <amui-button-toggle
            v-if="vehicleClassToggleOptions.length > 1"
            :options="vehicleClassToggleOptions"
            v-model="vehicleClassModel"
            @change="v => trackSearchParamMultiSelection('vehicleClass', [v])"
            class="am-search-hybrid-sidebar__vehicle-class-toggle"
          ></amui-button-toggle>
          <button
            class="am-search-hybrid-sidebar__reset"
            v-if="showResetLink"
            @click="onResetSearchParams"
          >
            <amui-icon name="restore" />
          </button>
        </div>
        <div class="am-search-hybrid-sidebar__usage-types">
          <div
            v-for="(amUsageTypeOption, index) in amUsageTypeOptionsOrdered"
            :key="index"
          >
            <amui-checkbox
              :val="amUsageTypeOption.value"
              :value="params.amUsageType"
              :label="amUsageTypeOption.alternateLabel"
              :disabled="amUsageTypeOption.disabled"
              checkable-when-disabled
              viewport="s"
              @change="onChangeAmUsageTypeProxy"
            />
          </div>
        </div>

        <div
          v-if="showUsedCarSealOptions"
          class="am-search-hybrid-sidebar__used-car-seal-wrapper"
        >
          <span>Gebrauchtwagensiegel</span>
          <div>
            <amui-chips
              :data="usedCarSealOptions"
              type="checkbox"
              :value="params.usedCarSeal"
              @change="onChangeUsedCarSealProxy"
            />
          </div>
        </div>

        <am-search-hybrid-category-selection
          :options="categoryOptions"
          v-model="params.category"
          @change="onChangeCategoryProxy"
          class="am-search-hybrid-sidebar__category-options"
        />

        <amui-sidebar-collapsable
          v-if="fuelOptions.length > 0"
          v-model="hasOpenFuelCollapsable"
          label="Treibstoff"
          class="am-search-hybrid-sidebar__section"
        >
          <amui-chips
            :data="fuelOptions"
            type="checkbox"
            :value="params.fuel"
            @change="onChangeFuelProxy"
            viewport="s"
          />
        </amui-sidebar-collapsable>
        <div
          v-if="fuelSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ fuelSelectedLabel }}
        </div>
        <amui-sidebar-collapsable
          v-model="hasOpenModelCollapsable"
          label="Marke/Modell"
          class="am-search-hybrid-sidebar__section"
        >
          <div
            v-for="(makeOption, index1) in makeOptions.filter(
              o => o.value !== ''
            )"
            :key="makeOption.value + '-' + index1"
            class="am-search-hybrid-sidebar__model-wrapper"
          >
            <amui-radio
              viewport="s"
              :disabled="makeOption.disabled"
              checkable-when-disabled
              :label="makeOption.label"
              :val="makeOption.value === null ? '' : makeOption.value"
              :value="makeModel === null ? '' : makeModel"
              @change="onChangeMakeProxy"
            />
            <div
              v-if="makeOption.value === makeModel"
              class="am-search-hybrid-sidebar__model-group-wrapper"
            >
              <amui-radio
                v-for="(modelGroup, index2) in modelGroupOptions.filter(
                  o => o.value !== null
                )"
                :key="modelGroup.value + '-' + index2"
                viewport="s"
                :disabled="modelGroup.disabled"
                checkable-when-disabled
                :label="modelGroup.label"
                :val="modelGroup.value === null ? '' : modelGroup.value"
                :value="modelGroupModel === null ? '' : modelGroupModel"
                @change="onChangeModelGroupProxy"
              />
            </div>
          </div>
        </amui-sidebar-collapsable>
        <div
          v-if="modelSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ modelSelectedLabel }}
        </div>
        <amui-sidebar-collapsable
          v-model="hasOpenPaymentCollapsable"
          label="Wie möchten Sie bezahlen?"
          class="am-search-hybrid-sidebar__section"
        >
          <amui-select
            :value="financingType"
            :options="financingTypeOptions"
            aria-label="Angaben zum Budget"
            viewport="s"
            @change="
              v => {
                trackSearchParamSingleSelection('financingType', v)
                financingType = v
              }
            "
          >
          </amui-select>

          <template v-if="hasSelectedPurchase">
            <amui-range-slider
              key="purchase-range-slider"
              :min="priceRangeOptions.min"
              :max="priceRangeOptions.max"
              :step="500"
              v-model="priceRangeModel"
              viewport="s"
              class="am-search-hybrid-sidebar__budget-slider"
              @change="v => trackConsumerPriceGrossRange(v)"
            >
              <template v-slot:from="{ value }">
                <amui-i18n
                  :term="$t('form.searchVehicleBudget.priceRangeLabelFrom')"
                  tag="span"
                >
                  <span slot="value" class="am-sfc-budget__slider-value">{{
                    formatPrice(value)
                  }}</span>
                </amui-i18n>
              </template>
              <template v-slot:to="{ value }">
                <amui-i18n
                  :term="$t('form.searchVehicleBudget.priceRangeLabelTo')"
                  tag="span"
                >
                  <span slot="value" class="am-sfc-budget__slider-value">{{
                    value === priceRangeOptions.max
                      ? 'beliebig'
                      : formatPrice(value)
                  }}</span>
                </amui-i18n>
              </template>
            </amui-range-slider>
            <amui-checkbox
              :val="true"
              :value="
                params.vatDeductible === null ? false : params.vatDeductible
              "
              @change="onChangeVatDeductible"
              :label="$t('form.searchVehicleBudget.vatDeductibleLabel')"
              viewport="s"
              class="am-search-hybrid-sidebar__vat-deductible"
            />
          </template>
          <template v-else-if="hasSelectedFinancing">
            <amui-range-slider
              key="financing-range-slider"
              :min="financingRateRangeOptions.min"
              :max="financingRateRangeOptions.max"
              :step="10"
              v-model="financingRateRangeModel"
              viewport="s"
              class="am-search-hybrid-sidebar__budget-slider"
              @change="v => trackFinancingMonthlyInstallmentRange(v)"
            >
              <template v-slot:from="{ value }">
                <amui-i18n
                  :term="
                    $t('form.searchVehicleBudget.financingRateRangeLabelFrom')
                  "
                  tag="span"
                >
                  <span slot="value" class="am-sfc-budget__slider-value">{{
                    formatPrice(value)
                  }}</span>
                </amui-i18n>
              </template>
              <template v-slot:to="{ value }">
                <amui-i18n
                  :term="
                    $t('form.searchVehicleBudget.financingRateRangeLabelTo')
                  "
                  tag="span"
                >
                  <span slot="value" class="am-sfc-budget__slider-value">{{
                    formatPrice(value)
                  }}</span>
                </amui-i18n>
              </template>
            </amui-range-slider>
            <amui-checkbox
              :val="true"
              :value="
                params.vatDeductible === null ? false : params.vatDeductible
              "
              @change="onChangeVatDeductible"
              :label="$t('form.searchVehicleBudget.vatDeductibleLabel')"
              viewport="s"
              class="am-search-hybrid-sidebar__vat-deductible"
            />
          </template>
          <template v-else-if="hasSelectedLeasing">
            <amui-range-slider
              key="leasing-range-slider"
              :min="leasingRateRangeOptions.min"
              :max="leasingRateRangeOptions.max"
              :step="10"
              v-model="leasingRateRangeModel"
              viewport="s"
              class="am-search-hybrid-sidebar__budget-slider"
              @change="v => trackLeasingGrossRateRange(v)"
            >
              <template v-slot:from="{ value }">
                <amui-i18n
                  :term="
                    $t('form.searchVehicleBudget.leasingRateRangeLabelFrom')
                  "
                  tag="span"
                >
                  <span slot="value" class="am-sfc-budget__slider-value">{{
                    formatPrice(value)
                  }}</span>
                </amui-i18n>
              </template>
              <template v-slot:to="{ value }">
                <amui-i18n
                  :term="$t('form.searchVehicleBudget.leasingRateRangeLabelTo')"
                  tag="span"
                >
                  <span slot="value" class="am-sfc-budget__slider-value">{{
                    formatPrice(value)
                  }}</span>
                </amui-i18n>
              </template>
            </amui-range-slider>

            <amui-checkbox
              :val="true"
              :value="
                params.vatDeductible === null ? false : params.vatDeductible
              "
              @change="onChangeVatDeductible"
              :label="$t('form.searchVehicleBudget.vatDeductibleLabel')"
              viewport="s"
              class="am-search-hybrid-sidebar__vat-deductible"
            />
          </template>
        </amui-sidebar-collapsable>
        <div
          v-if="paymentSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ paymentSelectedLabel }}
        </div>

        <amui-sidebar-collapsable
          v-if="hasSelectedLeasing"
          v-model="hasOpenLeasingTypeCollapsable"
          label="Angebote"
          class="am-search-hybrid-sidebar__section"
        >
          <amui-radio
            v-for="leasingTypeOption in leasingTypeOptions"
            :key="leasingTypeOption.value"
            v-model="leasingTypeModel"
            viewport="s"
            :val="leasingTypeOption.value"
            :label="leasingTypeOption.label"
          />
        </amui-sidebar-collapsable>
        <div
          v-if="leasingTypeSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ leasingTypeSelectedLabel }}
        </div>
        <amui-sidebar-collapsable
          v-model="hasOpenFirstRegistrationCollapsable"
          label="Erstzulassung"
          class="am-search-hybrid-sidebar__section"
        >
          <div class="am-search-hybrid-sidebar__first-registration">
            <div>
              <amui-select
                label="ab"
                :value="params.firstRegistration.min"
                :options="firstRegistrationOptions('min')"
                viewport="s"
                @change="
                  v => {
                    trackSearchParamRange('firstRegistration', [
                      v,
                      params.firstRegistration.max
                    ])
                    params.firstRegistration.min = v
                  }
                "
              >
              </amui-select>
            </div>
            <div>
              <amui-select
                label="bis"
                :value="params.firstRegistration.max"
                :options="firstRegistrationOptions('max')"
                viewport="s"
                @change="
                  v => {
                    trackSearchParamRange('firstRegistration', [
                      params.firstRegistration.min,
                      v
                    ])
                    params.firstRegistration.max = v
                  }
                "
              >
              </amui-select>
            </div>
          </div>
        </amui-sidebar-collapsable>
        <div
          v-if="firstRegistrationSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ firstRegistrationSelectedLabel }}
        </div>

        <amui-sidebar-collapsable
          v-model="hasOpenMileageCollapsable"
          label="Kilometer"
          class="am-search-hybrid-sidebar__section"
        >
          <amui-range-slider
            :min="mileageOptions.min"
            :max="mileageOptions.max"
            :step="mileageOptions.step"
            :unit="mileageOptions.unit"
            v-model="mileageModel"
            viewport="s"
            @change="v => trackMileage(v)"
          >
            <template v-slot:from="{ value }">
              von
              <span class="amui-font-medium amui-color-black">
                {{ formatMileage(value) }}
              </span>
              km
            </template>
            <template v-slot:to="{ value }">
              bis
              <span class="amui-font-medium amui-color-black">
                {{ formatMileage(value) }}
              </span>
              km
            </template>
          </amui-range-slider>
        </amui-sidebar-collapsable>
        <div
          v-if="mileageSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ mileageSelectedLabel }}
        </div>

        <amui-sidebar-collapsable
          v-model="hasOpenTechnicalDataCollapsable"
          label="Technische Daten"
          class="am-search-hybrid-sidebar__section"
        >
          <div
            v-if="isMotorbikeSearch && drivingModeOptions.length"
            class="am-search-hybrid-sidebar__driving-mode-wrapper"
          >
            <span>Antriebsart</span>
            <div>
              <div>
                <amui-chips
                  :data="drivingModeOptions"
                  type="checkbox"
                  :value="params.drivingMode"
                  viewport="s"
                  @change="
                    v => {
                      trackSearchParamMultiSelection('drivingMode', v)
                      params.drivingMode = v
                    }
                  "
                />
              </div>
            </div>
          </div>

          <div
            v-if="gearboxOptions.length > 0"
            class="am-search-hybrid-sidebar__gearbox-wrapper"
            :class="{
              'am-search-hybrid-sidebar__gearbox-wrapper--mt': isMotorbikeSearch
            }"
          >
            <span>Getriebe</span>
            <div>
              <amui-chips
                :data="gearboxOptions"
                type="checkbox"
                v-model="params.gearbox"
                viewport="s"
                @change="v => trackSearchParamMultiSelection('gearbox', v)"
              />
            </div>
          </div>

          <div class="am-search-hybrid-sidebar__cubic-capacity-wrapper">
            <span>Hubraum</span>
            <div>
              <div>
                <amui-select
                  label="von"
                  :value="params.cubicCapacity.min"
                  :options="cubicCapacityOptions('min')"
                  viewport="s"
                  @change="
                    v => {
                      trackSearchParamRange('cubicCapacity', [
                        v,
                        params.cubicCapacity.max
                      ])
                      params.cubicCapacity.min = v
                    }
                  "
                >
                </amui-select>
              </div>
              <div>
                <amui-select
                  label="bis"
                  :value="params.cubicCapacity.max"
                  :options="cubicCapacityOptions('max')"
                  viewport="s"
                  @change="
                    v => {
                      trackSearchParamRange('cubicCapacity', [
                        params.cubicCapacity.min,
                        v
                      ])
                      params.cubicCapacity.max = v
                    }
                  "
                >
                </amui-select>
              </div>
            </div>
          </div>

          <div class="am-search-hybrid-sidebar__power-wrapper">
            <span>Leistung</span>
            <div>
              <div>
                <amui-select
                  :value="powerType"
                  :options="powerTypeOptions"
                  viewport="s"
                  @change="
                    v => {
                      trackSearchParamSingleSelection('powerType', v)
                      powerType = v
                    }
                  "
                >
                </amui-select>
              </div>
            </div>
            <div>
              <div>
                <amui-select
                  label="von"
                  :value="params.power.min"
                  :options="powerOptions('min')"
                  viewport="s"
                  @change="
                    v => {
                      trackSearchParamRange('power', [v, params.power.max])
                      params.power.min = v
                    }
                  "
                >
                </amui-select>
              </div>
              <div>
                <amui-select
                  label="bis"
                  :value="params.power.max"
                  :options="powerOptions('max')"
                  viewport="s"
                  @change="
                    v => {
                      trackSearchParamRange('power', [params.power.min, v])
                      params.power.max = v
                    }
                  "
                >
                </amui-select>
              </div>
            </div>
          </div>
        </amui-sidebar-collapsable>
        <div
          v-if="technicalDataSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ technicalDataSelectedLabel }}
        </div>

        <amui-sidebar-collapsable
          v-if="!isMotorbikeSearch"
          v-model="hasOpenEnvironmentCollapsable"
          label="Umwelt"
          class="am-search-hybrid-sidebar__section"
        >
          <div class="am-search-hybrid-sidebar__consumption-wrapper">
            <div>
              <amui-select
                label="Kraftstoffverbrauch (komb.) bis"
                :value="params.consumptionCombined"
                :options="consumptionCombinedOptions"
                viewport="s"
                @change="
                  v => {
                    trackSearchParamSingleSelection('consumptionCombined', v)
                    params.consumptionCombined = v
                  }
                "
              >
              </amui-select>
            </div>
            <div>
              <amui-select
                label="Schadstoffklasse ab"
                :value="emissionClassModel"
                :options="emissionClassOptions"
                viewport="s"
                @change="
                  v => {
                    trackSearchParamSingleSelectWithConcatedValues(
                      'emissionClass',
                      v
                    )
                    emissionClassModel = v
                  }
                "
              >
              </amui-select>
            </div>
            <div>
              <amui-chips
                :data="
                  getMappedChipsData([
                    {
                      key: 'flags',
                      value: 'particulateFilterDiesel'
                    }
                  ])
                "
                type="checkbox"
                :value="particulateFilterDieselModel"
                viewport="s"
                @change="
                  v => {
                    trackMixedFlags(v, [
                      {
                        key: 'flags',
                        value: 'particulateFilterDiesel'
                      }
                    ])
                    particulateFilterDieselModel = v
                  }
                "
              />
            </div>
            <div>
              <amui-select
                label="Umweltplakette ab"
                :value="emissionStickerModel"
                :options="emissionStickerOptions"
                viewport="s"
                @change="
                  v => {
                    trackSearchParamSingleSelectWithConcatedValues(
                      'emissionSticker',
                      v
                    )
                    emissionStickerModel = v
                  }
                "
              >
              </amui-select>
            </div>
          </div>
        </amui-sidebar-collapsable>
        <div
          v-if="environmentSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ environmentSelectedLabel }}
        </div>

        <amui-sidebar-collapsable
          v-model="hasOpenOutsideCollapsable"
          label="Außen"
          class="am-search-hybrid-sidebar__section"
        >
          <div class="am-search-hybrid-sidebar__color-wrapper">
            <span>
              Außenfarbe
            </span>
            <div class="am-search-hybrid-sidebar__colors">
              <div v-for="(item, index) in exteriorColorsOptions" :key="index">
                <amui-checkbox
                  :label="item.label"
                  :value="params.exteriorColor"
                  :backgroundColor="item.backgroundColor"
                  :color="item.color"
                  :borderColor="item.borderColor"
                  :val="item.value"
                  viewport="s"
                  @change="
                    v => {
                      trackSearchParamMultiSelection('exteriorColor', v)
                      params.exteriorColor = v
                    }
                  "
                />
              </div>
            </div>
            <div class="am-search-hybrid-sidebar__exterior-color-metallic">
              <amui-chips
                :data="
                  getMappedChipsData([
                    {
                      key: 'flags',
                      value: 'metallic'
                    }
                  ])
                "
                type="checkbox"
                :value="metallicModel"
                viewport="s"
                @change="
                  v => {
                    trackMixedFlags(v, [
                      {
                        key: 'flags',
                        value: 'metallic'
                      }
                    ])
                    metallicModel = v
                  }
                "
              />
            </div>
          </div>

          <div
            v-if="!isMotorbikeSearch"
            class="am-search-hybrid-sidebar__chips-wrapper"
          >
            <span>Anhängerkupplung</span>
            <div>
              <amui-chips
                :data="trailerCouplingTypeOptions"
                type="radio"
                :value="params.trailerCouplingType"
                viewport="s"
                @change="
                  v => {
                    const newVal = v === '' ? null : v
                    trackSearchParamSingleSelection(
                      'trailerCouplingType',
                      newVal
                    )
                    params.trailerCouplingType = newVal
                  }
                "
              />
            </div>
          </div>

          <div
            v-if="!isMotorbikeSearch"
            class="am-search-hybrid-sidebar__chips-wrapper"
          >
            <span>Einparkhilfe</span>
            <div>
              <amui-chips
                :data="parkingAssistantsOptions"
                type="checkbox"
                :value="params.parkingAssistants"
                viewport="s"
                @change="
                  v => {
                    trackSearchParamMultiSelection('parkingAssistants', v)
                    params.parkingAssistants = v
                  }
                "
              />
            </div>
          </div>

          <div
            v-if="!isMotorbikeSearch"
            class="am-search-hybrid-sidebar__chips-wrapper"
          >
            <span>Tempomat</span>
            <div>
              <amui-chips
                :data="speedControlOptions"
                type="radio"
                viewport="s"
                :value="params.speedControl"
                @change="
                  v => {
                    const newVal = v === '' ? null : v
                    trackSearchParamSingleSelection('speedControl', newVal)
                    params.speedControl = newVal
                  }
                "
              />
            </div>
          </div>

          <div class="am-search-hybrid-sidebar__chips-wrapper">
            <span>Ausstattung</span>
            <div>
              <amui-chips
                :data="exteriorFeatureOptions"
                type="checkbox"
                :value="exteriorFeaturesModel"
                viewport="s"
                @change="
                  v => {
                    trackMixedFlags(v, exteriorFeatureListByVehicleClass)
                    exteriorFeaturesModel = v
                  }
                "
              />
            </div>
          </div>
        </amui-sidebar-collapsable>
        <div
          v-if="outsideSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ outsideSelectedLabel }}
        </div>

        <amui-sidebar-collapsable
          v-model="hasOpenInsideCollapsable"
          label="Innen"
          class="am-search-hybrid-sidebar__section"
        >
          <div class="am-search-hybrid-sidebar__color-wrapper">
            <span>
              Farbe der Innenausstattung
            </span>
            <div class="am-search-hybrid-sidebar__colors">
              <div v-for="(item, index) in interiorColorsOptions" :key="index">
                <amui-checkbox
                  :value="params.interiorColor"
                  :backgroundColor="item.backgroundColor"
                  :color="item.color"
                  :borderColor="item.borderColor"
                  :val="item.value"
                  :label="item.label"
                  viewport="s"
                  @change="
                    v => {
                      trackSearchParamMultiSelection('interiorColor', v)
                      params.interiorColor = v
                    }
                  "
                />
              </div>
            </div>
          </div>

          <div class="am-search-hybrid-sidebar__chips-wrapper">
            <span>Material der Innenausstattung</span>
            <div>
              <amui-chips
                :data="interiorTypeOptions"
                type="checkbox"
                :value="params.interiorType"
                viewport="s"
                @change="
                  v => {
                    trackSearchParamMultiSelection('interiorType', v)
                    params.interiorType = v
                  }
                "
              />
            </div>
          </div>
          <template v-if="!isMotorbikeSearch">
            <div class="am-search-hybrid-sidebar__chips-wrapper">
              <span>Airbags</span>
              <div>
                <amui-chips
                  :data="airbagOptions"
                  type="radio"
                  :value="params.airbag"
                  viewport="s"
                  @change="
                    v => {
                      const newVal = v === '' ? null : v
                      trackSearchParamSingleSelection('airbag', newVal)
                      params.airbag = newVal
                    }
                  "
                />
              </div>
            </div>

            <div class="am-search-hybrid-sidebar__climate-wrapper">
              <span>Klimatisierung</span>
              <div>
                <amui-radio
                  :value="climatisationRadioModel"
                  val=""
                  :label="$t('vehicle.climatisationANY')"
                  :checked="params.climatisation.length === 0"
                  viewport="s"
                  @change="
                    v => {
                      trackClimatisation(v)
                      climatisationRadioModel = v
                    }
                  "
                />
                <amui-radio
                  v-for="climatisationOption in climatisationOptions"
                  :key="climatisationOption.value"
                  :value="climatisationRadioModel"
                  :val="climatisationOption.value"
                  :label="climatisationOption.label"
                  viewport="s"
                  @change="
                    v => {
                      trackClimatisation(v)
                      climatisationRadioModel = v
                    }
                  "
                />
              </div>
              <div v-if="climatisationRadioModel === 'AUTOMATIC_CLIMATISATION'">
                <amui-chips
                  :data="climatisationChipOptions"
                  type="checkbox"
                  :value="climatisationChipModel"
                  viewport="s"
                  @change="
                    v => {
                      trackAutomaticClimatisation(v)
                      climatisationChipModel = v
                    }
                  "
                />
              </div>
            </div>

            <div class="am-search-hybrid-sidebar__chips-wrapper">
              <span>Extras</span>
              <div>
                <amui-chips
                  :data="interiorFeatureOptions"
                  type="checkbox"
                  :value="interiorFeaturesModel"
                  viewport="s"
                  @change="
                    v => {
                      trackMixedFlags(v, interiorFeatureListByVehicleClass)
                      interiorFeaturesModel = v
                    }
                  "
                />
              </div>
            </div>
          </template>
        </amui-sidebar-collapsable>
        <div
          v-if="insideSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ insideSelectedLabel }}
        </div>

        <amui-sidebar-collapsable
          v-model="hasOpenInseratCollapsable"
          label="Inserat"
          class="am-search-hybrid-sidebar__section"
        >
          <amui-select
            label="Inserat online seit"
            :value="params.onlineRange"
            :options="onlineRangeOptions"
            viewport="s"
            @change="
              v => {
                trackSearchParamSingleSelection('onlineRange', v)
                params.onlineRange = v
              }
            "
          >
          </amui-select>
        </amui-sidebar-collapsable>
        <div
          v-if="inseratSelectedLabel"
          class="am-search-hybrid-sidebar__selected-label"
        >
          {{ inseratSelectedLabel }}
        </div>
      </div>
      <div class="am-search-hybrid-sidebar__footer">
        <amui-button
          class="am-search-hybrid-sidebar__confirm-button"
          :label="buttonLabel"
          @click="$emit('close')"
        ></amui-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  formatMileage,
  formatCount,
  formatPrice,
  formatNumber
} from '../../../../../../utils/formatter'
import { mapState, mapGetters } from 'vuex'
import FacetMixin from './mixins/facet.mixin'
import searchTagsHelperMixin from '@/app/mixins/search-tags-helper.mixin'
import SearchParamsMapping from '@/app/mixins/search-params-mapping.mixin'
import {
  getExteriorFeatureListByVehicleClass,
  getInteriorFeatureListByVehicleClass
} from '@/shared/definitions/search'
import {
  computedPropertyForChipsModel,
  computedPropertyForSingleSelectModelBasedOnArray,
  computedPropertyForMultiSelectModelBasedOnArray
} from '@/shared/utils/search'
import FinancingMixin from './../../mixins/financing.mixin'
import initialSearchState from '@/app/store/modules/search-hybrid/initial-state'
import { AmuiRangeSlider } from '@/../ui/components/range-slider'
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiButtonToggle } from '@/../ui/components/button-toggle'
import { AmuiSelect } from '@/../ui/components/select'
import { AmuiI18n } from '@/../ui/components/i18n'
import { AmuiCheckbox } from '@/../ui/components/checkbox'
import { AmuiSidebarCollapsable } from '@/../ui/components/sidebar-collapsable'
import { AmuiChips } from '@/../ui/components/chips'
import { AmuiRadio } from '@/../ui/components/radio'
import { AmuiButton } from '@/../ui/components/button'
import AmSearchHybridCategorySelection from './../search-hybrid-category-selection/search-hybrid-category-selection.vue'
import trackingMapping from '@/shared/definitions/search-tracking'
import structuredClone from '@ungap/structured-clone'

import { getGTMTracker } from '@/app/tracking/gtm.js'

export default {
  name: 'AmSearchHybridSidebar',

  mixins: [
    FacetMixin,
    SearchParamsMapping,
    searchTagsHelperMixin,
    FinancingMixin
  ],

  components: {
    AmuiRangeSlider,
    AmuiIcon,
    AmuiButtonToggle,
    AmuiSelect,
    AmuiI18n,
    AmuiCheckbox,
    AmuiSidebarCollapsable,
    AmuiChips,
    AmuiRadio,
    AmuiButton,
    AmSearchHybridCategorySelection
  },

  props: {
    total: {
      type: Number,
      required: false,
      default: null
    },
    _params: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      gtm: null,
      isSidebar: true, // will be used for mixin scope /form structure for example)
      isExpanded: false,
      sidebarIsOpen: false, // remove
      hasOpenPaymentCollapsable: true,
      hasOpenLeasingTypeCollapsable: false,
      hasOpenFuelCollapsable: true,
      hasOpenModelCollapsable: true,
      hasOpenFirstRegistrationCollapsable: false,
      hasOpenMileageCollapsable: false,
      hasOpenTechnicalDataCollapsable: false,
      hasOpenEnvironmentCollapsable: false,
      hasOpenOutsideCollapsable: false,
      hasOpenInsideCollapsable: false,
      hasOpenInseratCollapsable: false,
      financingTypeWatcher: null,
      // copied
      financingType: null,
      paramsWatcher: null,
      propertyParamsWatcher: null,
      params: null,
      mileageOptions: {
        min: 0,
        max: 200000,
        step: 5000,
        label: '',
        unit: 'km'
      },
      powerType: 'ps',
      powerTypeOptions: [
        {
          label: 'PS',
          value: 'ps'
        },
        {
          label: 'kW',
          value: 'kw'
        }
      ],
      financingTypeOptions: [
        {
          label: 'Leasing',
          value: 'leasing',
          type: 'leasing'
        },
        {
          label: 'Finanzierung',
          value: 'financing',
          type: 'financing'
        },
        {
          label: 'Kauf',
          value: 'purchase',
          type: 'purchase'
        }
      ],
      priceRangeOptionsByVehicleClass: {
        Car: {
          min: 0,
          max: 200000
        },
        Motorbike: {
          min: 0,
          max: 30000
        }
      },
      leasingRateRangeOptionsByVehicleClass: {
        Car: {
          min: 50,
          max: 2500
        },
        Motorbike: {
          min: 50,
          max: 500
        }
      },
      financingRateRangeOptionsByVehicleClass: {
        Car: {
          min: 50,
          max: 2500
        },
        Motorbike: {
          min: 50,
          max: 500
        }
      },
      leasingTypeOptions: [
        {
          label: 'Alle Angebote',
          value: ''
        },
        {
          label: 'Gewerbekunden',
          value: 'commercial'
        },
        {
          label: 'Privatkunden',
          value: 'private'
        }
      ]
    }
  },

  computed: {
    showUsedCarSealOptions() {
      return (
        (this.params.amUsageType.includes('USED') ||
          this.params.amUsageType.includes('EMPLOYEES_CAR')) &&
        this.usedCarSealOptions.length
      )
    },
    leasingTypeSelectedLabel() {
      let label = null

      if (!this.hasOpenLeasingTypeCollapsable) {
        const searchTags = this.getSearchTagsByGroup('leasingType')

        if (searchTags.length) {
          label = searchTags[0].label
        }
      }

      return label
    },
    modelSelectedLabel() {
      let label = ''

      if (!this.hasOpenModelCollapsable) {
        const searchTags = this.getSearchTagsByGroup('model')

        if (this.makeModel && !this.modelGroupModel) {
          const foundMakeOption = this.makeOptions.find(
            d => d.value === this.makeModel
          )
          if (foundMakeOption) {
            label = foundMakeOption.label
          }
        } else if (this.modelGroupModel) {
          const foundModelGroupOption = this.modelGroupOptions.find(
            d => d.value === this.modelGroupModel
          )

          if (foundModelGroupOption) {
            label = searchTags[0].label
            label += ' ' + foundModelGroupOption.label
          }
        }

        const additionalTags = searchTags
          .filter(
            d => !['make', 'modelGroup'].includes(JSON.parse(d.value).key)
          )
          .map(d => d.label)
          .join(', ')

        if (additionalTags) {
          label = label !== '' ? label + ', ' + additionalTags : additionalTags
        }
      }

      return label
    },
    fuelSelectedLabel() {
      let label = ''

      if (!this.hasOpenFuelCollapsable) {
        const searchTags = this.getSearchTagsByGroup('fuel')

        label = searchTags.map(tag => tag.label).join(', ')
      }

      return label
    },
    paymentSelectedLabel() {
      let label = null

      if (!this.hasOpenPaymentCollapsable) {
        const searchTags = this.getSearchTagsByGroup('payment')
        label = searchTags.map(tag => tag.label).join(', ')
      }

      return label
    },
    firstRegistrationSelectedLabel() {
      let label = null

      if (!this.hasOpenFirstRegistrationCollapsable) {
        const searchTags = this.getSearchTagsByGroup('firstRegistration')

        if (searchTags.length) {
          label = searchTags.map(d => d.label).join(', ')
        }
      }

      return label
    },
    mileageSelectedLabel() {
      let label = null

      if (!this.hasOpenMileageCollapsable) {
        const searchTags = this.getSearchTagsByGroup('mileage')

        if (searchTags.length) {
          label = searchTags.map(d => d.label).join(', ')
        }
      }

      return label
    },
    technicalDataSelectedLabel() {
      let label = null

      if (!this.hasOpenTechnicalDataCollapsable) {
        const searchTags = this.getSearchTagsByGroup('technicalData')

        if (searchTags.length) {
          label = searchTags.map(d => d.label).join(', ')
        }
      }

      return label
    },
    environmentSelectedLabel() {
      let label = null

      if (!this.hasOpenEnvironmentCollapsable) {
        const searchTags = this.getSearchTagsByGroup('environment')

        if (searchTags.length) {
          label = searchTags.map(d => d.label).join(', ')
        }
      }

      return label
    },
    outsideSelectedLabel() {
      let label = null

      if (!this.hasOpenOutsideCollapsable) {
        const searchTags = this.getSearchTagsByGroup('outside')

        if (searchTags.length) {
          label = searchTags.map(d => d.label).join(', ')
        }
      }

      return label
    },
    insideSelectedLabel() {
      let label = null

      if (!this.hasOpenInsideCollapsable) {
        const searchTags = this.getSearchTagsByGroup('inside')

        if (searchTags.length) {
          label = searchTags.map(d => d.label).join(', ')
        }
      }

      return label
    },
    inseratSelectedLabel() {
      let label = null

      if (!this.hasOpenInseratCollapsable) {
        const searchTags = this.getSearchTagsByGroup('inserat')

        if (searchTags.length) {
          label = searchTags.map(d => d.label).join(', ')
        }
      }

      return label
    },
    buttonLabel() {
      // TODO: use other translation key
      return this.$tc('searchHybrid.subline', this.total, {
        count: formatCount(this.total)
      })
    },
    vehicleClassToggleOptions() {
      return this.availableVehicleClasses.map(vehicleClass => {
        return {
          value: vehicleClass,
          label: this.$t('vehicleClass.' + vehicleClass + '.select'),
          icon: vehicleClass.toLowerCase()
        }
      })
    },
    // new
    ...mapGetters('core', ['companyId']),
    ...mapState('core', {
      availableVehicleClasses: state => state.settings.vehicleClass.available
    }),
    ...mapGetters('searchHybrid', ['getInitialSearchParams']),

    isMotorbikeSearch() {
      return this.vehicleClassModel === 'Motorbike'
    },

    priceRangeOptions() {
      return this.priceRangeOptionsByVehicleClass[this.vehicleClassModel]
    },
    leasingRateRangeOptions() {
      return this.leasingRateRangeOptionsByVehicleClass[this.vehicleClassModel]
    },
    financingRateRangeOptions() {
      return this.financingRateRangeOptionsByVehicleClass[
        this.vehicleClassModel
      ]
    },

    vehicleClassModel: computedPropertyForSingleSelectModelBasedOnArray(
      'vehicleClass'
    ),

    interiorFeaturesModel: computedPropertyForChipsModel(context => {
      return getInteriorFeatureListByVehicleClass(context.vehicleClassModel)
    }),

    exteriorFeaturesModel: computedPropertyForChipsModel(context => {
      return getExteriorFeatureListByVehicleClass(context.vehicleClassModel)
    }),

    particulateFilterDieselModel: computedPropertyForChipsModel([
      {
        key: 'flags',
        value: 'particulateFilterDiesel'
      }
    ]),

    metallicModel: computedPropertyForChipsModel([
      {
        key: 'flags',
        value: 'metallic'
      }
    ]),

    emissionClassModel: computedPropertyForMultiSelectModelBasedOnArray(
      'emissionClass'
    ),

    emissionStickerModel: computedPropertyForMultiSelectModelBasedOnArray(
      'emissionSticker'
    ),

    makeModel: computedPropertyForSingleSelectModelBasedOnArray('make'),

    modelGroupModel: computedPropertyForSingleSelectModelBasedOnArray(
      'modelGroup'
    ),

    modelModel: computedPropertyForSingleSelectModelBasedOnArray('model'),

    mileageModel: {
      set(value) {
        const mileageObj = {
          min: value[0],
          max: value[1]
        }

        if (value[0] === this.mileageOptions.min) {
          mileageObj.min = null
        }

        if (value[0] === this.mileageOptions.max) {
          mileageObj.max = null
        }

        this.params.mileage = mileageObj
      },
      get() {
        const min =
          this.params.mileage.min === null
            ? this.mileageOptions.min
            : this.params.mileage.min
        const max =
          this.params.mileage.max === null
            ? this.mileageOptions.max
            : this.params.mileage.max
        return [min, max]
      }
    },

    searchButtonLabel() {
      let label = this.$tc('cta.search.label')

      if (this.total !== null) {
        label = this.$tc('cta.search.labelWithCount', this.total, {
          count: formatCount(this.total)
        })
      }
      return label
    },

    gearboxOptions() {
      return [
        {
          label: this.$t('vehicle.gearboxAUTOMATIC_GEAR'),
          value: 'AUTOMATIC_GEAR',
          disabled: false
        },
        {
          label: this.$t('vehicle.gearboxSEMIAUTOMATIC_GEAR'),
          value: 'SEMIAUTOMATIC_GEAR',
          disabled: false
        },
        {
          label: this.$t('vehicle.gearboxMANUAL_GEAR'),
          value: 'MANUAL_GEAR',
          disabled: false
        }
      ]
    },

    drivingModeOptions() {
      return [
        {
          label: this.$t('vehicle.drivingModeFULLY_SUSPENDED_DRIVE'),
          value: 'FULLY_SUSPENDED_DRIVE',
          disabled: false
        },
        {
          label: this.$t('vehicle.drivingModeCHAIN_DRIVE'),
          value: 'CHAIN_DRIVE',
          disabled: false
        },
        {
          label: this.$t('vehicle.drivingModeBELT_DRIVE'),
          value: 'BELT_DRIVE',
          disabled: false
        }
      ]
    },

    emissionStickerOptions() {
      const options = [
        {
          label: this.$t('vehicle.emissionStickerEMISSIONSSTICKER_GREEN'),
          value: 'EMISSIONSSTICKER_GREEN'
        },
        {
          label: this.$t('vehicle.emissionStickerEMISSIONSSTICKER_YELLOW'),
          value: 'EMISSIONSSTICKER_YELLOW,EMISSIONSSTICKER_GREEN'
        },
        {
          label: this.$t('vehicle.emissionStickerEMISSIONSSTICKER_RED'),
          value:
            'EMISSIONSSTICKER_RED,EMISSIONSSTICKER_YELLOW,EMISSIONSSTICKER_GREEN'
        },
        {
          label: this.$t('vehicle.emissionStickerEMISSIONSSTICKER_NONE'),
          value:
            'EMISSIONSSTICKER_NONE,EMISSIONSSTICKER_RED,EMISSIONSSTICKER_YELLOW,EMISSIONSSTICKER_GREEN'
        }
      ]
      const value = this.params.emissionSticker
      const defaultValue = this.getInitialSearchParams().emissionSticker
      if (value.length > 0) {
        options.unshift({
          label: this.$t('vehicle.reset'),
          value: defaultValue
        })
      }
      return options
    },

    exteriorColorsOptions() {
      return [
        {
          label: this.$t('vehicle.exteriorColorBEIGE'),
          value: 'BEIGE',
          color: '#000000',
          backgroundColor: '#E1D1B9',
          borderColor: '#E1D1B9',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorBLUE'),
          value: 'BLUE',
          color: '#ffffff',
          backgroundColor: '#0081F8',
          borderColor: '#0081F8',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorBROWN'),
          value: 'BROWN',
          color: '#ffffff',
          backgroundColor: '#7B5028',
          borderColor: '#7B5028',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorYELLOW'),
          value: 'YELLOW',
          color: '#000000',
          backgroundColor: '#FFD600',
          borderColor: '#FFD600',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorGOLD'),
          value: 'GOLD',
          color: '#000000',
          backgroundColor: '#C1A595',
          borderColor: '#C1A595',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorGREY'),
          value: 'GREY',
          selected: false,
          color: '#000000',
          backgroundColor: '#A5AAB6',
          borderColor: '#A5AAB6'
        },
        {
          label: this.$t('vehicle.exteriorColorGREEN'),
          value: 'GREEN',
          color: '#ffffff',
          backgroundColor: '#17A700',
          borderColor: '#17A700',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorORANGE'),
          value: 'ORANGE',
          color: '#000000',
          backgroundColor: '#FF8A00',
          borderColor: '#FF8A00',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorRED'),
          value: 'RED',
          color: '#ffffff',
          backgroundColor: '#B70303',
          borderColor: '#B70303',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorBLACK'),
          value: 'BLACK',
          color: '#ffffff',
          backgroundColor: '#000000',
          borderColor: '#000000',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorSILVER'),
          value: 'SILVER',
          color: '#000000',
          backgroundColor: '#D1D3D8',
          borderColor: '#D1D3D8',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorPURPLE'),
          value: 'PURPLE',
          color: '#ffffff',
          backgroundColor: '#8F5FF5',
          borderColor: '#8F5FF5',
          selected: false
        },
        {
          label: this.$t('vehicle.exteriorColorWHITE'),
          value: 'WHITE',
          color: '#000000',
          backgroundColor: '#ffffff',
          borderColor: '#636D7E',
          selected: false
        }
      ]
    },

    interiorColorsOptions() {
      return [
        {
          label: this.$t('vehicle.interiorColorBEIGE'),
          value: 'BEIGE',
          selected: false,
          color: '#000000',
          backgroundColor: '#E1D1B9',
          borderColor: '#E1D1B9'
        },
        {
          label: this.$t('vehicle.interiorColorBROWN'),
          value: 'BROWN',
          color: '#ffffff',
          backgroundColor: '#7B5028',
          borderColor: '#7B5028',
          selected: false
        },
        {
          label: this.$t('vehicle.interiorColorGREY'),
          value: 'GREY',
          selected: false,
          color: '#000000',
          backgroundColor: '#A5AAB6',
          borderColor: '#A5AAB6'
        },
        {
          label: this.$t('vehicle.interiorColorBLACK'),
          value: 'BLACK',
          selected: false,
          color: '#ffffff',
          backgroundColor: '#000000',
          borderColor: '#000000'
        },
        {
          label: this.$t('vehicle.interiorColorOTHER_INTERIOR_COLOR'),
          value: 'OTHER_INTERIOR_COLOR',
          selected: false,
          color: null,
          backgroundColor: null,
          borderColor: null
        }
      ]
    },

    trailerCouplingTypeOptions() {
      return [
        {
          label: this.$t('vehicle.trailerCouplingTypeTRAILER_COUPLING_FIX'),
          value: 'TRAILER_COUPLING_FIX',
          disabled: false
        },
        {
          label: this.$t(
            'vehicle.trailerCouplingTypeTRAILER_COUPLING_SWIVELING'
          ),
          value: 'TRAILER_COUPLING_SWIVELING',
          disabled: false
        },
        {
          label: this.$t(
            'vehicle.trailerCouplingTypeTRAILER_COUPLING_DETACHABLE'
          ),
          value: 'TRAILER_COUPLING_DETACHABLE',
          disabled: false
        }
      ]
    },

    parkingAssistantsOptions() {
      return [
        {
          label: this.$t('vehicle.parkingAssistantsREAR_SENSORS'),
          value: 'REAR_SENSORS',
          disabled: false
        },
        {
          label: this.$t('vehicle.parkingAssistantsFRONT_SENSORS'),
          value: 'FRONT_SENSORS',
          disabled: false
        },
        {
          label: this.$t('vehicle.parkingAssistantsREAR_VIEW_CAM'),
          value: 'REAR_VIEW_CAM',
          disabled: false
        },
        {
          label: this.$t('vehicle.parkingAssistantsCAM_360_DEGREES'),
          value: 'CAM_360_DEGREES',
          disabled: false
        },
        {
          label: this.$t('vehicle.parkingAssistantsAUTOMATIC_PARKING'),
          value: 'AUTOMATIC_PARKING',
          disabled: false
        }
      ]
    },

    speedControlOptions() {
      return [
        {
          label: this.$t('vehicle.speedControlCRUISE_CONTROL'),
          value: 'CRUISE_CONTROL',
          disabled: false
        },
        {
          label: this.$t('vehicle.speedControlADAPTIVE_CRUISE_CONTROL'),
          value: 'ADAPTIVE_CRUISE_CONTROL',
          disabled: false
        }
      ]
    },

    interiorFeatureListByVehicleClass() {
      return getInteriorFeatureListByVehicleClass(this.vehicleClassModel)
    },

    interiorFeatureOptions() {
      return this.getMappedChipsData(this.interiorFeatureListByVehicleClass)
    },

    exteriorFeatureListByVehicleClass() {
      return getExteriorFeatureListByVehicleClass(this.vehicleClassModel)
    },

    exteriorFeatureOptions() {
      return this.getMappedChipsData(this.exteriorFeatureListByVehicleClass)
    },

    interiorTypeOptions() {
      return [
        {
          label: this.$t('vehicle.interiorTypeLEATHER'),
          value: 'LEATHER'
        },
        {
          label: this.$t('vehicle.interiorTypePARTIAL_LEATHER'),
          value: 'PARTIAL_LEATHER'
        },
        {
          label: this.$t('vehicle.interiorTypeFABRIC'),
          value: 'FABRIC'
        },
        {
          label: this.$t('vehicle.interiorTypeVELOUR'),
          value: 'VELOUR'
        },
        {
          label: this.$t('vehicle.interiorTypeALCANTARA'),
          value: 'ALCANTARA'
        },
        {
          label: this.$t('vehicle.interiorTypeOTHER_INTERIOR_TYPE'),
          value: 'OTHER_INTERIOR_TYPE'
        }
      ]
    },

    airbagOptions() {
      return [
        {
          label: this.$t('vehicle.airbagDRIVER_AIRBAG'),
          value: 'DRIVER_AIRBAG'
        },
        {
          label: this.$t('vehicle.airbagFRONT_AIRBAGS'),
          value: 'FRONT_AIRBAGS'
        },
        {
          label: this.$t('vehicle.airbagFRONT_AND_SIDE_AIRBAGS'),
          value: 'FRONT_AND_SIDE_AIRBAGS'
        },
        {
          label: this.$t('vehicle.airbagFRONT_AND_SIDE_AND_MORE_AIRBAGS'),
          value: 'FRONT_AND_SIDE_AND_MORE_AIRBAGS'
        }
      ]
    },

    climatisationOptions() {
      return [
        {
          label: this.$t('vehicle.climatisationNO_CLIMATISATION'),
          value: 'NO_CLIMATISATION'
        },
        {
          label: this.$t('vehicle.climatisationMANUAL_CLIMATISATION'),
          value: 'MANUAL_CLIMATISATION'
        },
        {
          label: this.$t('vehicle.climatisationAUTOMATIC_CLIMATISATION'),
          value: 'AUTOMATIC_CLIMATISATION'
        }
      ]
    },

    climatisationChipOptions() {
      return [
        {
          label: this.$t(
            'vehicle.climatisationAUTOMATIC_CLIMATISATION_2_ZONES'
          ),
          value: 'AUTOMATIC_CLIMATISATION_2_ZONES'
        },
        {
          label: this.$t(
            'vehicle.climatisationAUTOMATIC_CLIMATISATION_3_ZONES'
          ),
          value: 'AUTOMATIC_CLIMATISATION_3_ZONES'
        },
        {
          label: this.$t(
            'vehicle.climatisationAUTOMATIC_CLIMATISATION_4_ZONES'
          ),
          value: 'AUTOMATIC_CLIMATISATION_4_ZONES'
        }
      ]
    },

    emissionClassOptions() {
      const options = [
        {
          label: this.$t('vehicle.emissionClassEURO6D'),
          value: 'EURO6D'
        },
        {
          label: this.$t('vehicle.emissionClassEURO6D_TEMP'),
          value: 'EURO6D_TEMP,EURO6D'
        },
        {
          label: this.$t('vehicle.emissionClassEURO6C'),
          value: 'EURO6C,EURO6D_TEMP,EURO6D'
        },
        {
          label: this.$t('vehicle.emissionClassEURO6'),
          value: 'EURO6,EURO6C,EURO6D_TEMP,EURO6D'
        },
        {
          label: this.$t('vehicle.emissionClassEURO5'),
          value: 'EURO5,EURO6,EURO6C,EURO6D_TEMP,EURO6D'
        },
        {
          label: this.$t('vehicle.emissionClassEURO4'),
          value: 'EURO4,EURO5,EURO6,EURO6C,EURO6D_TEMP,EURO6D'
        },
        {
          label: this.$t('vehicle.emissionClassEURO3'),
          value: 'EURO3,EURO4,EURO5,EURO6,EURO6C,EURO6D_TEMP,EURO6D'
        },
        {
          label: this.$t('vehicle.emissionClassEURO2'),
          value: 'EURO2,EURO3,EURO4,EURO5,EURO6,EURO6C,EURO6D_TEMP,EURO6D'
        },
        {
          label: this.$t('vehicle.emissionClassEURO1'),
          value: 'EURO1,EURO2,EURO3,EURO4,EURO5,EURO6,EURO6C,EURO6D_TEMP,EURO6D'
        }
      ]
      const value = this.params.emissionClass
      const defaultValue = this.getInitialSearchParams().emissionClass
      if (value.length > 0) {
        options.unshift({
          label: this.$t('vehicle.reset'),
          value: defaultValue
        })
      }
      return options
    },

    consumptionCombinedOptions() {
      const options = Array.from({ length: 7 }, (_, i) => 3 + 2 * i).map(
        item => ({
          label: this.$t('vehicle.consumptionCombinedTEXT', {
            consumption: item,
            unit: this.$t('vehicle.consumptionUnitLITER')
          }),
          value: item
        })
      )
      const value = this.params.consumptionCombined
      const defaultValue = this.getInitialSearchParams().consumptionCombined
      if (value) {
        options.unshift({
          label: this.$t('vehicle.reset'),
          value: defaultValue
        })
      }
      return options
    },

    climatisationRadioModel: {
      get() {
        const validValues = [
          'NO_CLIMATISATION',
          'MANUAL_CLIMATISATION',
          'AUTOMATIC_CLIMATISATION'
        ]
        let found = this.params.climatisation.find(c => validValues.includes(c))

        if (found === undefined) {
          // check if zones are active, then it should check AUTOMATIC_CLIMATISATION
          if (this.climatisationChipModel.length) {
            found = validValues[2]
          }
        }

        return found === undefined ? '' : found
      },
      set(value) {
        const validValues = [
          'NO_CLIMATISATION',
          'MANUAL_CLIMATISATION',
          'AUTOMATIC_CLIMATISATION'
        ]

        let newClimatisation = this.params.climatisation.filter(
          c => !validValues.includes(c)
        )

        if (validValues.includes(value)) {
          newClimatisation.push(value)
        }

        // TODO check if not already exists
        if (newClimatisation.includes('AUTOMATIC_CLIMATISATION')) {
          const valuesToAdd = [
            'AUTOMATIC_CLIMATISATION_2_ZONES',
            'AUTOMATIC_CLIMATISATION_3_ZONES',
            'AUTOMATIC_CLIMATISATION_4_ZONES'
          ]

          valuesToAdd.forEach(v => {
            if (!newClimatisation.includes(v)) {
              newClimatisation.push(v)
            }
          })
        } else {
          newClimatisation = newClimatisation.filter(c =>
            validValues.includes(c)
          )
        }

        this.params.climatisation = newClimatisation
      }
    },

    climatisationChipModel: {
      get() {
        const validValues = [
          'AUTOMATIC_CLIMATISATION_2_ZONES',
          'AUTOMATIC_CLIMATISATION_3_ZONES',
          'AUTOMATIC_CLIMATISATION_4_ZONES'
        ]

        if (this.params.climatisation.includes('AUTOMATIC_CLIMATISATION')) {
          return []
        } else {
          return this.params.climatisation.filter(c => validValues.includes(c))
        }
      },
      set(values) {
        const validValues = [
          'AUTOMATIC_CLIMATISATION_2_ZONES',
          'AUTOMATIC_CLIMATISATION_3_ZONES',
          'AUTOMATIC_CLIMATISATION_4_ZONES'
        ]

        let newClimatisation = this.params.climatisation.filter(
          c => !validValues.includes(c)
        )

        if (values.length) {
          newClimatisation = newClimatisation.filter(
            c => c !== 'AUTOMATIC_CLIMATISATION'
          )
        } else {
          values = [
            'AUTOMATIC_CLIMATISATION',
            'AUTOMATIC_CLIMATISATION_2_ZONES',
            'AUTOMATIC_CLIMATISATION_3_ZONES',
            'AUTOMATIC_CLIMATISATION_4_ZONES'
          ]
        }

        this.params.climatisation = newClimatisation.concat(values)
      }
    },

    priceRangeModel: {
      set(value) {
        this.params.consumerPriceGrossRange = {
          min: value[0] === this.priceRangeOptions.min ? null : value[0],
          max: value[1] === this.priceRangeOptions.max ? null : value[1]
        }
      },
      get() {
        const min = Number.isInteger(this.params.consumerPriceGrossRange.min)
          ? this.params.consumerPriceGrossRange.min
          : this.priceRangeOptions.min
        const max = Number.isInteger(this.params.consumerPriceGrossRange.max)
          ? this.params.consumerPriceGrossRange.max
          : this.priceRangeOptions.max
        return [min, max]
      }
    },

    financingRateRangeModel: {
      set(value) {
        this.params.financingMonthlyInstallmentRange = {
          min: value[0],
          max: value[1]
        }
      },
      get() {
        const min = Number.isInteger(
          this.params.financingMonthlyInstallmentRange.min
        )
          ? this.params.financingMonthlyInstallmentRange.min
          : this.financingRateRangeOptions.min
        const max = Number.isInteger(
          this.params.financingMonthlyInstallmentRange.max
        )
          ? this.params.financingMonthlyInstallmentRange.max
          : this.financingRateRangeOptions.max
        return [min, max]
      }
    },

    leasingRateRangeModel: {
      set(value) {
        this.params.leasingGrossRateRange = {
          min: value[0],
          max: value[1]
        }
      },
      get() {
        const min = Number.isInteger(this.params.leasingGrossRateRange.min)
          ? this.params.leasingGrossRateRange.min
          : this.leasingRateRangeOptions.min
        const max = Number.isInteger(this.params.leasingGrossRateRange.max)
          ? this.params.leasingGrossRateRange.max
          : this.leasingRateRangeOptions.max
        return [min, max]
      }
    },

    leasingTypeModel: {
      set(value) {
        this.params.isCommercialOffer =
          value === 'commercial' ? true : value === 'private' ? false : null
      },
      get() {
        return this.params.isCommercialOffer === true
          ? 'commercial'
          : this.params.isCommercialOffer === false
          ? 'private'
          : ''
      }
    },

    onlineRangeOptions() {
      const options = [
        {
          label: this.$t('vehicle.onlineRangeONE_DAY'),
          value: 'ONE_DAY'
        },
        {
          label: this.$t('vehicle.onlineRangeTHREE_DAYS'),
          value: 'THREE_DAYS'
        },
        {
          label: this.$t('vehicle.onlineRangeFIVE_DAYS'),
          value: 'FIVE_DAYS'
        },
        {
          label: this.$t('vehicle.onlineRangeMORE_THAN_FIVE_DAYS'),
          value: 'MORE_THAN_FIVE_DAYS'
        }
      ]
      const value = this.params.onlineRange
      const defaultValue = this.getInitialSearchParams().onlineRange
      if (value) {
        options.unshift({
          label: this.$t('vehicle.reset'),
          value: defaultValue
        })
      }
      return options
    },

    amUsageTypeOptionsOrdered() {
      const order = [
        'NEW',
        'USED',
        'DEMONSTRATION',
        'EMPLOYEES_CAR',
        'PRE_REGISTRATION',
        'CLASSIC'
      ]

      const options = []

      order.forEach(key => {
        let found = this.amUsageTypeOptions.find(o => o.value === key)

        if (found !== undefined) {
          options.push(found)
        }
      })

      return options
    },
    hasSelectedPurchase() {
      return (
        this.financingTypeOptions.find(
          o => o.value === this.financingType && o.type === 'purchase'
        ) !== undefined
      )
    },
    hasSelectedLeasing() {
      return (
        this.financingTypeOptions.find(
          o => o.value === this.financingType && o.type === 'leasing'
        ) !== undefined
      )
    },
    hasSelectedFinancing() {
      return (
        this.financingTypeOptions.find(
          o => o.value === this.financingType && o.type === 'financing'
        ) !== undefined
      )
    }
  },

  created() {
    const { financingType, params } = this.processParams(this._params)

    this.financingType = financingType
    this.params = params

    // start watcher for changes
    this.registerParamsWatcher()
    this.registerPropertyParamsWatcher()
    this.registerFinancingTypeWatcher()
  },

  mounted() {
    this.gtm = getGTMTracker()
    this.initFacetVehicleSearch()
    this.isExpanded = this.shouldBeExpanded()
  },

  methods: {
    getInitialSearchParamsWithCurrentVehicleClass() {
      return Object.assign({}, initialSearchState().params, {
        vehicleClass: this.params.vehicleClass
      })
    },
    processParams(params) {
      const financingType = this.getFinancingTypeByParams(params)

      return {
        financingType,
        params: this.getParamsModifiedByFinancingType(financingType, params)
      }
    },

    getParamsModifiedByFinancingType(
      financingType,
      params,
      applyCleanReset = false
    ) {
      return this.getBudgetParamsByCurrentSelection(
        financingType,
        params,
        applyCleanReset
      )
    },

    unregisterFinancingTypeWatcher() {
      this.financingTypeWatcher && this.financingTypeWatcher()
    },
    registerFinancingTypeWatcher() {
      this.financingTypeWatcher = this.$watch('financingType', {
        handler(newVal, oldVal) {
          this.hasOpenLeasingTypeCollapsable = false
          if (oldVal !== null) {
            this.params = this.getParamsModifiedByFinancingType(
              newVal,
              this.params,
              true
            )
          }
        }
      })
    },
    closeCollapsables() {
      this.hasOpenPaymentCollapsable = true
      this.hasOpenLeasingTypeCollapsable = false
      this.hasOpenFuelCollapsable = true
      this.hasOpenModelCollapsable = true
      this.hasOpenFirstRegistrationCollapsable = false
      this.hasOpenMileageCollapsable = false
      this.hasOpenTechnicalDataCollapsable = false
      this.hasOpenEnvironmentCollapsable = false
      this.hasOpenOutsideCollapsable = false
      this.hasOpenInsideCollapsable = false
      this.hasOpenInseratCollapsable = false
      this.isExpanded = false
    },
    shouldBeExpanded() {
      let isExpanded = false

      if (this.firstRegistrationSelectedLabel) {
        isExpanded = true
      }

      if (this.mileageSelectedLabel) {
        isExpanded = true
      }

      if (this.technicalDataSelectedLabel) {
        isExpanded = true
      }

      if (this.environmentSelectedLabel) {
        isExpanded = true
      }

      if (this.outsideSelectedLabel) {
        isExpanded = true
      }

      if (this.insideSelectedLabel) {
        isExpanded = true
      }

      if (this.inseratSelectedLabel) {
        isExpanded = true
      }

      return isExpanded
    },

    unregisterParamsWatcher() {
      this.paramsWatcher && this.paramsWatcher()
      this.paramsWatcher = null
    },

    unregisterPropertyParamsWatcher() {
      this.propertyParamsWatcher && this.propertyParamsWatcher()
      this.propertyParamsWatcher = null
    },

    // entweder hier den watch auf _params ausstellen
    // oder mal prüfen ob es ein reference problem gibt
    registerParamsWatcher() {
      this.paramsWatcher = this.$watch('params', {
        handler(newParams) {
          let paramsToEmit = newParams

          if (this.params.vehicleClass[0] !== this._params.vehicleClass[0]) {
            paramsToEmit = Object.assign({}, this.getInitialSearchParams(), {
              vehicleClass: newParams.vehicleClass
            })

            const { financingType, params } = this.processParams(paramsToEmit)

            this.financingType = financingType
            paramsToEmit = params

            // important to reInit the facet search logic when vehicleClass changed, because it only works with one certain vehicleClass
            this.closeCollapsables()
            this.resetFacetSearchSelectables()
            this.initFacetVehicleSearch()
          }

          this.$emit('change', structuredClone(paramsToEmit))
        },
        deep: true
      })
    },

    onResetSearchParams() {
      const initialParams = Object.assign({}, this.getInitialSearchParams(), {
        vehicleClass: this.params.vehicleClass
      })

      const { financingType, params } = this.processParams(initialParams)

      this.financingType = financingType
      this.params = params

      this.closeCollapsables()
      this.resetFacetSearchSelectables()
    },

    registerPropertyParamsWatcher() {
      this.propertyParamsWatcher = this.$watch('_params', {
        handler(newParams) {
          // nextTick seems to be necessary to be sure that the watchers are working correctly, maybe in future think about the data flow approach here again
          // might be also an option to only emit events and the data source of truth is the _params given by property? (huge effort to change that)

          this.unregisterParamsWatcher()

          this.$nextTick(() => {
            const facetFieldsToCheck = [
              'make',
              'modelGroup',
              'model',
              'category',
              'condition',
              'usageType',
              'amUsageType',
              'fuel'
            ]

            const needFacetUpdate = facetFieldsToCheck.some(fieldName => {
              return (
                newParams[fieldName].length !== this.params[fieldName].length
              )
            })

            if (needFacetUpdate) {
              this.initFacetVehicleSearch()
            }

            const { financingType, params } = this.processParams(newParams)

            this.financingType = financingType
            this.params = structuredClone(params)

            this.registerParamsWatcher()
          })
        },
        deep: true
      })
    },

    onSearchResult() {
      const query = this.getUrlQueryByStore()
      this.$router.push({ name: 'app', query })
    },
    formatMileage(value) {
      return formatMileage(value)
    },
    formatPrice(value) {
      return formatPrice(value, false, false)
    },

    setFlags(flags) {
      const removeDuplicates = data => [...new Set(data)]
      const interiorFeatureFlags = this.interiorFeaturesModel
        .map(d => JSON.parse(d))
        .filter(d => typeof d === 'string')
      const exteriorFeatureFlags = this.exteriorFeaturesModel
        .map(d => JSON.parse(d))
        .filter(d => typeof d === 'string')

      this.params.flags = removeDuplicates(
        interiorFeatureFlags.concat(exteriorFeatureFlags).concat(flags)
      )
    },

    getMappedChipsData(list) {
      return list.map(entry => {
        const translationKey =
          entry.key !== 'flags' && entry.value !== undefined
            ? entry.key + '' + entry.value
            : entry.value

        return {
          label: this.$t('vehicle.' + translationKey),
          value: JSON.stringify(entry),
          disabled: false
        }
      })
    },

    onFavoritesClick() {
      this.$router.push({ name: 'favorites' })
    },

    firstRegistrationOptions(type) {
      let years = []
      const value = this.params.firstRegistration[type]
      const defaultValue = this.getInitialSearchParams().firstRegistration[type]
      if (value) {
        years.push({
          label: this.$t('vehicle.reset'),
          value: defaultValue
        })
      }
      const year = new Date().getFullYear()
      years = years.concat(
        Array.from({ length: year - (year - 8) }, (_, i) => year - i).map(
          item => ({
            label: item,
            value: parseInt(item + (type === 'min' ? '01' : '12'))
          })
        )
      )
      return years
    },

    cubicCapacityOptions(type) {
      const value = this.params.cubicCapacity[type]
      const values = [50, 80, 125, 250, 500, 750, 1000, 1500]
      const defaultValue = this.getInitialSearchParams().cubicCapacity[type]

      const options = values.map(v => {
        return {
          label: this.$t('vehicle.cubicCapacityTEXT').replace(
            '{cubicCapacity}',
            formatNumber(v)
          ),
          value: v
        }
      })

      if (value) {
        options.unshift({
          label: this.$t('vehicle.reset'),
          value: defaultValue
        })
      }

      return options
    },

    powerOptions(type) {
      let cf = 1
      if (this.powerType === 'ps') cf = 1.35962
      const options = Array.from({ length: 20 }, (_, i) => 20 * (i + 1)).map(
        item => ({
          label: Math.round((item * cf) / 10) * 10,
          value: Math.round(item / 10) * 10
        })
      )
      const value = this.params.power[type]
      const defaultValue = this.getInitialSearchParams().power[type]

      if (value) {
        options.unshift({
          label: this.$t('vehicle.reset'),
          value: defaultValue
        })
      }
      return options
    },

    onChangeVatDeductible(checked) {
      this.trackSearchParamBoolean('vatDeductible', checked)
      this.params.vatDeductible = checked === false ? null : checked
    },

    // proxies to inject tracking before invoking methods of facet search

    onChangeAmUsageTypeProxy(values) {
      this.trackSearchParamMultiSelection('amUsageType', values)
      this.onChangeAmUsageType(values)
    },

    onChangeUsedCarSealProxy(values) {
      this.trackSearchParamMultiSelection('usedCarSeal', values)
      this.onChangeUsedCarSeal(values)
    },

    onChangeCategoryProxy(values) {
      this.trackSearchParamMultiSelection('category', values)
      this.onChangeCategory(values)
    },

    onChangeFuelProxy(values) {
      this.trackSearchParamMultiSelection('fuel', values)
      this.onChangeFuel(values)
    },

    onChangeMakeProxy(value) {
      this.trackSearchParamMultiSelection('make', [value])
      this.onChangeMake(value === '' ? null : value)
    },

    onChangeModelGroupProxy(value) {
      this.trackSearchParamMultiSelection('modelGroup', [value])
      this.onChangeModelGroup(value === '' ? null : value)
    },

    trackSearchParamSingleSelectWithConcatedValues(property, values) {
      const prevValue = this._params[property].length
        ? this._params[property][0]
        : null
      const value = typeof values === 'string' ? values.split(',')[0] : null

      if (value !== prevValue) {
        if (value === null) {
          this.trackSearchParam(property, prevValue, false)
        } else {
          this.trackSearchParam(property, value, true)
        }
      }
    },

    trackClimatisation(value) {
      if (
        [
          '',
          'NO_CLIMATISATION',
          'MANUAL_CLIMATISATION',
          'AUTOMATIC_CLIMATISATION'
        ].includes(value)
      ) {
        this.trackSearchParam(
          'climatisation',
          value === '' ? 'ANY' : value,
          true
        )
      }
    },

    // TODO: similar to trackFlags, maybe they can share a generic method together in the future
    trackAutomaticClimatisation(values) {
      const whitelist = [
        'AUTOMATIC_CLIMATISATION',
        'AUTOMATIC_CLIMATISATION_2_ZONES',
        'AUTOMATIC_CLIMATISATION_3_ZONES',
        'AUTOMATIC_CLIMATISATION_4_ZONES'
      ]

      let prevValues = this._params['climatisation'].filter(v =>
        whitelist.includes(v)
      )

      if (prevValues.includes('AUTOMATIC_CLIMATISATION')) {
        prevValues = []
      }

      const newValues = values.filter(v => !prevValues.includes(v))

      if (newValues.length) {
        this.trackSearchParam('climatisation', newValues[0], true)
      } else {
        const removedValues = prevValues.filter(v => {
          return !values.includes(v)
        })

        if (removedValues.length) {
          this.trackSearchParam('climatisation', removedValues[0], false)
        }
      }
    },

    trackMileage(values) {
      this.trackSearchParamRange('mileage', values, {
        min: this.mileageOptions.min,
        max: this.mileageOptions.max
      })
    },

    trackConsumerPriceGrossRange(values) {
      this.trackSearchParamRange('consumerPriceGrossRange', values, {
        min: this.priceRangeOptions.min,
        max: this.priceRangeOptions.max
      })
    },

    trackFinancingMonthlyInstallmentRange(values) {
      this.trackSearchParamRange('financingMonthlyInstallmentRange', values, {
        min: this.financingRateRangeOptions.min,
        max: this.financingRateRangeOptions.max
      })
    },

    trackLeasingGrossRateRange(values) {
      this.trackSearchParamRange('leasingGrossRateRange', values, {
        min: this.leasingRateRangeOptions.min,
        max: this.leasingRateRangeOptions.max
      })
    },

    trackMixedFlags(values, allowedValues = []) {
      // native flags
      this.trackFlags(
        values
          .map(v => JSON.parse(v))
          .filter(obj => obj.key === 'flags')
          .map(obj => obj.value),
        allowedValues.filter(v => v.key === 'flags').map(v => v.value)
      )

      // all none flags, they are all of type multi selection
      const uniqueProperties = allowedValues
        .map(av => av.key)
        .filter((value, index, self) => {
          return self.indexOf(value) === index && value !== 'flags'
        })

      uniqueProperties.forEach(property => {
        this.trackSearchParamMultiSelection(
          property,
          values
            .map(v => JSON.parse(v))
            .filter(obj => obj.key === property)
            .map(obj => obj.value)
        )
      })
    },

    trackFlags(currentFlags, allowedFlags = []) {
      const prevFlags = this._params['flags']
        .filter(f => allowedFlags.includes(f.name))
        .map(f => f.name)

      const newFlags = currentFlags.filter(flagName => {
        return !prevFlags.includes(flagName)
      })

      if (newFlags.length) {
        this.trackSearchParam('flags', newFlags[0], true)
      } else {
        const removedFlags = prevFlags.filter(flagName => {
          return !currentFlags.includes(flagName)
        })

        if (removedFlags.length) {
          this.trackSearchParam('flags', removedFlags[0], false)
        }
      }
    },

    trackSearchParamMultiSelection(property, values) {
      // assume that this method will be triggered as soon as exactly one value has beed added or removed
      const prevValues = this._params[property]
      const newValues = values.filter(v => !prevValues.includes(v))

      if (newValues.length) {
        this.trackSearchParam(property, newValues[0], true)
      } else {
        const removedValues = prevValues.filter(v => !values.includes(v))
        if (removedValues.length) {
          this.trackSearchParam(property, removedValues[0], false)
        }
      }
    },

    trackSearchParamSingleSelection(property, value) {
      const prevValue = this._params[property]

      if (value !== prevValue) {
        if (value === null) {
          this.trackSearchParam(property, prevValue, false)
        } else {
          this.trackSearchParam(property, value, true)
        }
      }
    },

    trackSearchParamBoolean(property, value) {
      const prevValue = this._params[property]

      if (value !== prevValue) {
        this.trackSearchParam(property, value, value === true)
      }
    },

    trackSearchParamRange(property, values, options) {
      // assume that this method will be triggered as soon as exactly one of both values changed
      const prevValues = Object.keys(this._params[property]).map(
        k => this._params[property][k]
      )

      let hasNewMinValue = values[0] !== prevValues[0]

      if (
        options?.min !== undefined &&
        values[0] === options.min &&
        prevValues[0] === null
      ) {
        hasNewMinValue = false
      }

      if (hasNewMinValue) {
        let value

        if (values[0] === null) {
          value = prevValues[0]
        } else {
          value = values[0]
        }

        this.trackSearchParam(
          { property, subProperty: 'min' },
          value,
          values[0] !== null
        )
      } else {
        const hasNewMaxValue = values[1] !== prevValues[1]
        if (hasNewMaxValue) {
          let value

          if (values[1] === null) {
            value = prevValues[1]
          } else {
            value =
              options?.max !== undefined && values[1] === options.max
                ? 'unlimited'
                : values[1]
          }

          this.trackSearchParam(
            { property, subProperty: 'max' },
            value,
            values[1] !== null
          )
        }
      }
    },

    getTrackingPropertyConfig(property) {
      let config = null

      if (typeof property === 'string') {
        if (trackingMapping[property] !== undefined) {
          config = trackingMapping[property]
        }
      } else if ('property' in property && 'subProperty' in property) {
        if (
          trackingMapping[property.property][property.subProperty] !== undefined
        ) {
          config = trackingMapping[property.property][property.subProperty]
        }
      }

      return config
    },

    getResolvedTrackingPropertyObject(property, value, config) {
      let resolvedObject = null

      let resolvedValue = null

      if (typeof config.value === 'function') {
        resolvedValue = config.value(value)
      } else if (typeof config.value === 'string') {
        resolvedValue = this.$t(
          config.value
            .replace('{vehicleClass}', this.vehicleClassModel)
            .replace('{property}', property)
            .replace('{value}', value)
        )
      }

      if (resolvedValue !== null) {
        resolvedObject = {
          property: config.property,
          value: resolvedValue
        }
      }

      return resolvedObject
    },

    trackSearchParam(property, value, isAdded) {
      const getTrackObject = (property, value) => {
        let payload = null
        let resolvedTrackingPropertyObject = null
        let extendedProperties = {}

        const config = this.getTrackingPropertyConfig(property)

        const propertyName =
          typeof property === 'string' ? property : property.property

        if (config !== null) {
          resolvedTrackingPropertyObject = this.getResolvedTrackingPropertyObject(
            propertyName,
            value,
            config
          )
        }

        if (resolvedTrackingPropertyObject !== null) {
          payload = {
            [resolvedTrackingPropertyObject.property]:
              resolvedTrackingPropertyObject.value
          }
        }

        if (Array.isArray(config.extendBy)) {
          const propertiesToResolveByConfig = {
            financingType: this.financingType
          }

          config.extendBy.forEach(property => {
            if (propertiesToResolveByConfig[property] !== undefined) {
              const value = propertiesToResolveByConfig[property]
              const config = this.getTrackingPropertyConfig(property)

              if (config !== null) {
                const resolvedObject = this.getResolvedTrackingPropertyObject(
                  property,
                  value,
                  config
                )

                if (resolvedObject !== null) {
                  extendedProperties[resolvedObject.property] =
                    resolvedObject.value
                }
              }
            } else if (property === 'currency') {
              extendedProperties.currency = 'EUR'
            }
          })
        }

        return Object.assign({}, payload, extendedProperties)
      }

      const payload = getTrackObject(property, value)

      if (payload !== null) {
        this.gtm.trackEvent({
          name: isAdded ? 'search_criteria_added' : 'search_criteria_removed',
          payload
        })
      }
    }
  }
}
</script>
