<template>
  <div
    class="am-search-hybrid"
    :class="{
      'am-search-hybrid--visible-sidebar': hasVisibleSidebar,
      'am-search-hybrid--no-transitions': preventTransitions
    }"
  >
    <div>
      <div
        class="am-search-hybrid__compact-toolbar-wrapper"
        :class="{
          'am-search-hybrid__compact-toolbar-wrapper--widget':
            appMode === 'widget'
        }"
      >
        <amui-compact-toolbar class="am-search-hybrid__compact-toolbar">
          <amui-compact-link
            v-if="appMode === 'widget' && favoriteVehiclesCount"
            :label="$t('sh.toolbar.favorites')"
            icon="favorite"
            :badge="favoriteVehiclesCount"
            @click.native="openFavorites"
          />
          <amui-compact-link
            ref="filterIcon"
            :label="$t('sh.toolbar.search')"
            icon="tune-simple"
            class="am-search-hybrid__sidebar-toggle"
            @click.native="toggleSidebarVisibility"
          />
        </amui-compact-toolbar>
      </div>
      <div class="am-search-hybrid__content-wrapper">
        <div
          class="am-search-hybrid__sidebar-background"
          @click="closeSidebar"
        ></div>
        <div class="am-search-hybrid__sidebar-wrapper">
          <am-search-hybrid-sidebar
            ref="sidebar"
            :total="total"
            :_params="params"
            @close="closeSidebar"
            @change="params => onParamsChange(params)"
          />
        </div>
        <div class="am-search-hybrid__results-wrapper" ref="resultsWrapper">
          <div class="am-search-hybrid__results-inner" ref="results">
            <amui-chip-cloud
              v-if="currentSearchTags.length"
              :data="currentSearchTags"
              show-less-label="Weniger anzeigen"
              show-more-label="Mehr anzeigen"
              :max-visible-items-s="8"
              @remove="onRemoveSearchTag"
              class="am-search-hybrid__selected-properties"
              viewport="s"
            >
              <button
                v-if="showResetLink"
                @click="onResetSearchParams"
                class="am-search-hybrid__reset"
              >
                <amui-icon name="restore" />
              </button>
            </amui-chip-cloud>

            <div class="am-search-hybrid__meta-toolbar">
              <div class="am-search-hybrid__hits">
                {{ $t('sh.resultCount', { count: formattedCount }) }}
              </div>
              <div>
                <a
                  :href="sortUrl"
                  class="am-search-hybrid__sort-link"
                  aria-label="Sortierung wechseln"
                >
                  <amui-icon
                    :name="'sort-' + (sortAsc ? 'ascending' : 'descending')"
                    title="Sortiert nach Preis"
                    @click.native.stop.prevent="onToggleSort"
                  />
                </a>
              </div>
            </div>

            <div v-if="total === 0" class="am-search-hybrid__no-results">
              <span>Ihre Suche ergab leider keine Treffer.</span>
              <div>Bitte passen Sie Ihre Filter an.</div>
            </div>

            <div v-if="rawVehicleData" class="am-search-hybrid__results">
              <am-vehicle-tile-extended
                v-for="vehicle of vehicles"
                :key="vehicle._id"
                :media="vehicle.media"
                :lcp-image-source="vehicle.lcpImageSource"
                :efficiency-class="vehicle.efficiencyClass"
                :efficiency-text="vehicle.efficiencyText"
                :title="vehicle.title"
                :isFavorite="vehicle.isFavorite"
                :rate="vehicle.rate"
                :make="vehicle.make"
                :am-model-year="vehicle.amModelYear"
                :model="vehicle.model"
                :usage-type="vehicle.usageType"
                :availability-in-weeks="vehicle.availabilityInWeeks"
                :availability-label="vehicle.availabilityLabel"
                :customer-highlights="vehicle.customerHighlights"
                :features="vehicle.features"
                :vehicle-class="vehicle.vehicleClass"
                :vehicle-url="vehicle.url"
                :price="vehicle.price"
                :price-tax-info="vehicle.priceTaxInfo"
                :crossedOutPrice="vehicle.crossedOutPrice"
                :crossedOutPriceReferenceNumber="
                  vehicle.crossedOutPriceReferenceNumber
                "
                @view="onViewVehicle(vehicle.id)"
                @favour="onFavoriteChange(vehicle.id)"
              >
              </am-vehicle-tile-extended>
            </div>

            <div
              class="am-search-hybrid__load-more-wrapper amui-mt-16 amui-mt-24:m amui-mt-32:xl"
            >
              <amui-button
                v-if="hasMoreVehiclesToLoad"
                type="secondary"
                label="Weitere laden..."
                :href="loadMoreUrl"
                @click.native.stop.prevent="onLoadMore"
              />
            </div>
          </div>

          <am-legal-foot-note
            v-if="total > 0"
            class="am-search-hybrid__main-legal"
          />
        </div>
      </div>
    </div>

    <am-search-hybrid-footer
      class="am-search-hybrid__footer"
      :class="{
        'am-search-hybrid__footer--hidden': hasIntersectingFilterIcon
      }"
      :favorites="favoriteVehiclesCount"
      @show-favorites="openFavorites"
      @toggle-filter-panel="toggleSidebarVisibility"
    ></am-search-hybrid-footer>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { formatCount } from '../../../../utils/formatter'
import { getId as getVehicleId } from './../../../../utils/vehicle'
import AmLegalFootNote from '@/app/components/legal-foot-note/legal-foot-note'
import vehicleCardHelperMixin from '@/app/mixins/vehicle-card-helper.mixin'
import searchTagsHelperMixin from '@/app/mixins/search-tags-helper.mixin'
import SearchParamsMapping from '@/app/mixins/search-params-mapping.mixin'
import SeoMixin from './mixins/seo.mixin'
import AmSearchHybridSidebar from './components/search-hybrid-sidebar/search-hybrid-sidebar'
import AmVehicleTileExtended from '@/shared/components/list/vehicle-tile-extended/vehicle-tile-extended'
// import AmVehicleModelTile from '@/shared/components/list/vehicle-model-tile'
import FinancingMixin from './mixins/financing.mixin'
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiButton } from '@/../ui/components/button'
import { AmuiChipCloud } from '@/../ui/components/chip-cloud'
import { AmuiCompactToolbar } from '@/../ui/components/compact-toolbar'
import { AmuiCompactLink } from '@/../ui/components/compact-link'
import AmSearchHybridFooter from './components/search-hybrid-footer/search-hybrid-footer.vue'
import vehicleTrackingMixin from '@/app/mixins/vehicle-tracking.mixin'
import trackingMapping from '@/shared/definitions/search-tracking'

import { getGTMTracker } from '@/app/tracking/gtm.js'

export default {
  name: 'AmSearchHybrid',

  mixins: [
    vehicleCardHelperMixin,
    SearchParamsMapping,
    searchTagsHelperMixin,
    SeoMixin,
    FinancingMixin,
    vehicleTrackingMixin
  ],

  components: {
    AmVehicleTileExtended,
    AmLegalFootNote,
    AmSearchHybridSidebar,
    AmuiIcon,
    AmuiButton,
    AmuiChipCloud,
    AmuiCompactToolbar,
    AmuiCompactLink,
    AmSearchHybridFooter
  },

  metaInfo() {
    const link = []

    if (this.canonicalUrl) {
      link.push({
        rel: 'canonical',
        href: this.canonicalUrl,
        vmid: 'canonical'
      })
    }

    if (this.lcpImageSource) {
      link.push({
        rel: 'preload',
        as: 'image',
        href: this.lcpImageSource
      })
    }

    return this
      ? {
          title: this.seoTitle,
          titleTemplate: '%s',
          meta: [{ name: 'description', content: this.seoDescription }],
          link
        }
      : null
  },

  data() {
    return {
      gtm: null,
      isSidebar: true,
      params: null,
      sortAsc: true,
      preventTransitions: false,
      hasVisibleSidebar: false,
      resizeTimer: null,
      routeQueryWatch: null,
      routeWatch: null,
      intersectionObserverInstance: null,
      intersectionObserverInstance2: null,
      hasIntersectingFilterIcon: false,
      // TODO: min/max values are duplicated with sidebar, needs to be sourced out in a config
      priceRangeOptionsByVehicleClass: {
        Car: {
          min: 0,
          max: 200000
        },
        Motorbike: {
          min: 0,
          max: 30000
        }
      },
      leasingRateRangeOptionsByVehicleClass: {
        Car: {
          min: 50,
          max: 2500
        },
        Motorbike: {
          min: 50,
          max: 500
        }
      },
      financingRateRangeOptionsByVehicleClass: {
        Car: {
          min: 50,
          max: 2500
        },
        Motorbike: {
          min: 50,
          max: 500
        }
      }
    }
  },

  computed: {
    ...mapGetters('core', ['companyId', 'canonicalBaseUrl']),
    ...mapState('core', ['appMode']),
    ...mapState('searchHybrid', ['rawVehicleData', 'paginationData']),
    ...mapState('favorites', {
      favoriteVehiclesCount: state => state.vehicles.length
    }),
    ...mapGetters('searchHybrid', ['getInitialSearchParams']),
    canonicalUrl() {
      const initialParams = this.getInitialSearchParams()

      const omit = (key, obj) => {
        // eslint-disable-next-line no-unused-vars
        const { [key]: omitted, ...rest } = obj
        return rest
      }

      const hasChangedFilterParams =
        JSON.stringify(omit('vehicleClass', this.params)) !==
        JSON.stringify(omit('vehicleClass', initialParams))

      let query = this.getUrlQueryByLocale({
        vehicleClass: this.params.vehicleClass[0]
      })

      if (!hasChangedFilterParams && this.paginationData.page > 1) {
        query = Object.assign({}, query, {
          page:
            this.paginationData.page > 1 ? this.paginationData.page : undefined
        })
      }

      const segment = this.$router.resolve(
        Object.assign({}, this.$route, { query })
      ).resolved.fullPath

      return this.canonicalBaseUrl + segment
    },
    sortUrl() {
      let url = null

      const query = this.getUrlQueryByLocale(this.params)
      url = this.$router.resolve({
        name: 'app',
        query: Object.assign({}, query, {
          page: this.paginationData.page,
          sort: this.sortAsc ? 'desc' : undefined
        })
      }).href

      return url
    },
    loadMoreUrl() {
      let url = null

      if (this.hasMoreVehiclesToLoad) {
        const query = this.getUrlQueryByLocale(this.params)
        url = this.$router.resolve({
          name: 'app',
          query: Object.assign({}, query, {
            page: this.paginationData.page + 1,
            sort: this.sortAsc ? undefined : 'desc'
          })
        }).href
      }

      return url
    },
    showResetLink() {
      const initialParams = this.getInitialSearchParams()

      const omit = (key, obj) => {
        // eslint-disable-next-line no-unused-vars
        const { [key]: omitted, ...rest } = obj
        return rest
      }

      return (
        JSON.stringify(omit('vehicleClass', this.params)) !==
        JSON.stringify(omit('vehicleClass', initialParams))
      )
    },
    formattedCount() {
      return formatCount(this.total)
    },
    total() {
      let total = null

      if (this.paginationData !== null) {
        total = this.paginationData.total
      }
      return total
    },
    vehicleUrlQuery() {
      const query = {}
      const selectedPaymentMethod = this.getCurrentSelectedPaymentMethod()

      if (['financing', 'leasing'].includes(selectedPaymentMethod)) {
        query.pm = selectedPaymentMethod
      }

      return query
    },
    vehicles() {
      let vehicles = []

      if (this.rawVehicleData) {
        vehicles = this.rawVehicleData.map((rawVehicle, i) => {
          const mappedVehicleCardData = this.getMappedVehicleCardData(
            rawVehicle
          )
          const media = (mappedVehicleCardData?.media || []).length
            ? mappedVehicleCardData.media[0]
            : null

          const vehicleUrl = this.$router.resolve({
            name: 'detail',
            params: {
              id: rawVehicle.mobileAdId
            },
            query: this.vehicleUrlQuery
          }).href

          return Object.assign({}, mappedVehicleCardData, {
            lcpImageSource:
              media && i === 0 ? this.getImageThumbSource(media) : null,
            url: vehicleUrl
          })
        })
      }
      return vehicles
    },
    lcpImageSource() {
      return this.appMode !== 'widget' && this.vehicles.length
        ? this.vehicles[0].lcpImageSource
        : null
    },
    hasMoreVehiclesToLoad() {
      let hasMore = false

      if (this.paginationData !== null) {
        hasMore = this.paginationData.page < this.paginationData.pages
      }

      return hasMore
    },
    priceRangeOptions() {
      return this.priceRangeOptionsByVehicleClass[this.params.vehicleClass[0]]
    },
    leasingRateRangeOptions() {
      return this.leasingRateRangeOptionsByVehicleClass[
        this.params.vehicleClass[0]
      ]
    },
    financingRateRangeOptions() {
      return this.financingRateRangeOptionsByVehicleClass[
        this.params.vehicleClass[0]
      ]
    }
  },

  created() {
    // TODO: not really in sync between ssr and widget
    if (this.$route.query.page !== undefined) {
      this._setPage(parseInt(this.$route.query.page))
    }

    if (this.$route.query.sort !== undefined) {
      this.sortAsc = this.$route.query.sort === 'asc'
    }

    this.params = this.getStoreParamsByQuery()
  },

  serverPrefetch() {
    return this.searchVehicles()
  },

  mounted() {
    this.gtm = getGTMTracker()
    this.updateQuery(true)

    if (this.rawVehicleData === null) {
      this.searchVehicles().then(res => {
        this.setResultsWrapperHeight()
        this.trackViewItemList(res.docs)
      })
    } else {
      this.trackViewItemList(this.rawVehicleData)
    }

    this.mql = window.matchMedia('(max-width: 1279px)')

    this.mql.addEventListener('change', this.handleMediaQuery)

    this.handleMediaQuery(this.mql)

    window.addEventListener('resize', this.handleResize)
  },

  beforeDestroy() {
    this.mql.removeEventListener('change', this.handleMediaQuery)
    this.unregisterRouteQueryWatch()
    this.routeWatch && this.routeWatch()
    window.removeEventListener('resize', this.handleResize)
    this.intersectionObserverInstance &&
      this.intersectionObserverInstance.disconnect()
    this.intersectionObserverInstance2 &&
      this.intersectionObserverInstance2.disconnect()
    this._resetState()
  },

  methods: {
    ...mapActions('favorites', ['toggleFavoriteStateByRawVehicleData']),
    ...mapActions('searchHybrid', {
      _fetchVehicles: 'fetchVehicles',
      _fetchFurtherVehicles: 'fetchFurtherVehicles',
      _setPage: 'setPage',
      _resetState: 'resetState'
    }),
    handleMediaQuery(mql) {
      if (mql.matches) {
        this.initIntersectionObserver()
      }
    },
    initIntersectionObserver() {
      // observing the filter icon
      if (this.intersectionObserverInstance === null) {
        this.intersectionObserverInstance = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              this.hasIntersectingFilterIcon = entry.isIntersecting
            })
          },
          { threshold: 1 }
        )

        this.intersectionObserverInstance.observe(this.$refs.filterIcon.$el)
      }

      // observing bottom of search result page
      if (this.intersectionObserverInstance2 === null) {
        this.intersectionObserverInstance2 = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              this.hasIntersectingFilterIcon = !entry.isIntersecting
            })
          },
          { rootMargin: '-100% 0px 0px 0px' }
        )

        this.intersectionObserverInstance2.observe(this.$el)
      }
    },
    getImageThumbSource(media) {
      const getSourceByWidth = width => {
        let src = null

        if (media.type === 'cpo') {
          const splittedSegments = media.normal.split('/')
          const filename = splittedSegments.pop()
          src = splittedSegments.join('/') + '/tr:w-' + width + '/' + filename
        } else {
          src = media.normal
        }

        return src
      }

      return getSourceByWidth(280)
    },

    onRemoveSearchTag(tag) {
      this.trackRemoveSearchTag(tag)
      this.params = this.removeSearchTag(tag, this.params)

      this.searchVehicles().then(res => {
        this.setResultsWrapperHeight()
        this.trackViewItemList(res.docs)
      })
      this.updateQuery()
    },

    unregisterRouteQueryWatch() {
      this.routeQueryWatch && this.routeQueryWatch()
    },

    // not sure if we really need that at the moment, its maybe useful for SPA where the query changed without any other trigger
    registerRouteQueryWatch() {
      this.routeQueryWatch = this.$watch('$route.query', {
        handler() {
          this.params = this.getStoreParamsByQuery()
          this.searchVehicles().then(() => {
            this.setResultsWrapperHeight()
          })
          this.updateQuery()
        },
        deep: true
      })
    },

    updateQuery(replace = false) {
      this.unregisterRouteQueryWatch()

      let query = this.getUrlQueryByLocale(this.params)

      query = Object.assign({}, query, {
        page:
          this.paginationData.page > 1 ? this.paginationData.page : undefined,
        sort: this.sortAsc ? undefined : 'desc'
      })

      if (replace) {
        this.$router.replace({ name: 'app', query })
      } else {
        this.$router.push({ name: 'app', query })
      }

      this.registerRouteQueryWatch()
    },

    setResultsWrapperHeight() {
      if (
        !this.$refs.resultsWrapper.style.minHeight ||
        parseInt(this.$refs.resultsWrapper.style.minHeight) <
          this.$refs.resultsWrapper.clientHeight
      ) {
        this.$refs.resultsWrapper.style.minHeight =
          this.$refs.resultsWrapper.clientHeight + 'px'
      }

      if (
        Math.abs(this.$refs.resultsWrapper.getBoundingClientRect().top) >
        this.$refs.results.clientHeight
      ) {
        window.scrollBy({
          top: this.$refs.resultsWrapper.getBoundingClientRect().top
        })

        window.setTimeout(() => {
          this.$refs.resultsWrapper.style.minHeight = '100vh'
        }, 300)
      }
    },

    handleResize() {
      this.preventTransitions = true
      clearTimeout(this.resizeTimer)
      this.resizeTimer = setTimeout(() => {
        this.preventTransitions = false
      }, 300)
    },

    getAutouncleVehicleUrl(vehicle) {
      let url = vehicle.url

      if (
        vehicle.autounclePriceRatingIntegration &&
        vehicle.autounclePriceRatingIntegration.settings?.vehicleUrl
      ) {
        url = vehicle.autounclePriceRatingIntegration.settings?.vehicleUrl.replace(
          '{mobileAdId}',
          vehicle.mobileAdId
        )
      }

      return url
    },

    onResetSearchParams() {
      this.$refs.sidebar.onResetSearchParams()
    },
    onToggleSort() {
      this.sortAsc = !this.sortAsc
      this.searchVehicles({ page: 1 }).then(res => {
        this.setResultsWrapperHeight()
        this.trackViewItemList(res.docs)
      })
      this.updateQuery()
    },
    searchVehicles(context = {}) {
      const currentPage = context.page || this.paginationData.page
      const options = {
        offset:
          this.paginationData.limit * currentPage - this.paginationData.limit,
        limit: this.paginationData.limit,
        sort:
          (this.sortAsc ? '' : '-') +
          'price.amConsumerPriceGrossCent mobileAdId'
      }

      const params = this.getGraphQLParamsByLocale(this.params)
      return this._fetchVehicles({ params, options }).catch(err => {
        // TODD: error handling
        console.log('missing error handler', err)
      })
    },
    fetchFurtherVehicles() {
      const params = this.getGraphQLParamsByLocale(this.params)

      this._setPage(this.paginationData.page + 1)

      const options = {
        offset:
          this.paginationData.limit * this.paginationData.page -
          this.paginationData.limit,
        limit: 20,
        sort:
          (this.sortAsc ? '' : '-') +
          'price.amConsumerPriceGrossCent mobileAdId'
      }

      return this._fetchFurtherVehicles({ params, options }).catch(err => {
        // TODD: error handling
        console.log('missing error handler', err)
      })
    },
    getCurrentSelectedPaymentMethod() {
      let selectedPaymentMethod = 'purchase'

      if (
        this.params.financingMonthlyInstallmentRange.min !== null ||
        this.params.financingMonthlyInstallmentRange.max !== null
      ) {
        selectedPaymentMethod = 'financing'
      } else if (
        this.params.leasingGrossRateRange.min !== null ||
        this.params.leasingGrossRateRange.max !== null
      ) {
        selectedPaymentMethod = 'leasing'
      }

      return selectedPaymentMethod
    },
    onViewVehicle(vehicleId) {
      this.trackSelectItem(
        this.rawVehicleData.find(v => v.mobileAdId === vehicleId)
      )

      this.$router.push({
        name: 'detail',
        params: {
          id: vehicleId
        },
        query: this.vehicleUrlQuery
      })
    },
    onFavoriteChange(vehicleId) {
      if (Array.isArray(this.rawVehicleData)) {
        const foundRawVehicleData = this.rawVehicleData.find(rawVehicleData => {
          return getVehicleId(rawVehicleData) === vehicleId
        })

        if (foundRawVehicleData !== undefined) {
          this.toggleFavoriteStateByRawVehicleData(foundRawVehicleData).then(
            added => {
              if (added) {
                this.trackAddToWishlist(foundRawVehicleData)
              }
            }
          )
        }
      }
    },
    toggleSidebarVisibility() {
      if (this.hasVisibleSidebar) {
        this.closeSidebar()
      } else {
        this.openSidebar()
      }
    },
    openSidebar() {
      document.body.classList.add('am-search-hybrid--prevent-scroll')
      this.hasVisibleSidebar = true
    },
    closeSidebar() {
      document.body.classList.remove('am-search-hybrid--prevent-scroll')
      this.hasVisibleSidebar = false
    },
    openFavorites() {
      this.$router.push({ name: 'favorites' })
    },
    onLoadMore() {
      this.fetchFurtherVehicles().then(res => {
        this.setResultsWrapperHeight()
        this.trackViewItemList(res.docs)
      })
      this.updateQuery()
    },
    onParamsChange(params) {
      this.params = params

      this.paginationData.page = 1
      this.searchVehicles().then(res => {
        this.setResultsWrapperHeight()
        this.trackViewItemList(res.docs)
      })
      this.updateQuery()
    },
    trackViewItemList(items) {
      if (items.length) {
        const payload = {
          ecommerce: {
            items: items.map(item => this.getVehicleTrackingObject(item))
          }
        }
        this.gtm.trackEvent({
          name: 'view_item_list',
          payload
        })
      } else {
        this.trackEmptySearchResultList()
      }
    },
    trackSelectItem(item) {
      const payload = {
        ecommerce: {
          items: [this.getVehicleTrackingObject(item)]
        }
      }

      this.gtm.trackEvent({
        name: 'select_item',
        payload
      })
    },
    trackAddToWishlist(item) {
      const vehicleTrackingObject = this.getVehicleTrackingObject(item)

      const payload = {
        ecommerce: {
          currency: 'EUR',
          value: vehicleTrackingObject.price,
          items: [vehicleTrackingObject]
        },
        component: 'srl'
      }

      this.gtm.trackEvent({
        name: 'add_to_wishlist',
        payload
      })
    },
    trackRemoveSearchTag(tag) {
      const obj = JSON.parse(tag)
      const rangeKeys = []

      if (obj.value === 'both') {
        rangeKeys.push('min')
        rangeKeys.push('max')
      } else if (obj.value === 'min') {
        rangeKeys.push('min')
      } else if (obj.value === 'max') {
        rangeKeys.push('max')
      }

      if (
        ['emissionClass', 'emissionSticker'].includes(obj.key) &&
        Array.isArray(obj.value) &&
        obj.value.length
      ) {
        // edge cases for params that are based on having multiple values
        this.trackSearchParam(obj.key, obj.value[0], false)
      } else if (rangeKeys.length) {
        // all range based params
        rangeKeys.forEach(rangeKey => {
          const value = this.params[obj.key][rangeKey]

          const getRangeOptions = property => {
            const optionsByPropertyMap = {
              consumerPriceGrossRange: this.priceRangeOptions,
              financingMonthlyInstallmentRange: this.financingRateRangeOptions,
              leasingGrossRateRange: this.leasingRateRangeOptions
            }

            return optionsByPropertyMap[property] || null
          }

          const rangeOptions = getRangeOptions(obj.key)

          this.trackSearchParam(
            { property: obj.key, subProperty: rangeKey },
            rangeKey === 'max' && value === rangeOptions?.max
              ? 'unlimited'
              : value,
            false
          )
        })
      } else {
        // all other simple params
        this.trackSearchParam(obj.key, obj.value, false)
      }
    },
    trackEmptySearchResultList() {
      const initialParams = this.getInitialSearchParams()

      const changedParams = []

      Object.keys(initialParams).forEach(key => {
        const initialValue = initialParams[key]
        const currentValue = this.params[key]
        const config = this.getTrackingPropertyConfig(key)

        if (config !== null) {
          if (initialValue === null && currentValue !== null) {
            const resolvedTrackingPropertyObject = this.getResolvedTrackingPropertyObject(
              key,
              currentValue,
              config
            )
            changedParams.push({
              [resolvedTrackingPropertyObject.property]:
                resolvedTrackingPropertyObject.value
            })
          } else if (Array.isArray(initialValue) && currentValue.length > 0) {
            let resolvedTrackingPropertyObject

            const mappedValues = currentValue
              .filter((cv, index) => {
                // edge cases for params that are based on having multiple values
                return (
                  !['emissionClass', 'emissionSticker'].includes(key) ||
                  index < 1
                )
              })
              .map(cv => {
                let v

                if (typeof cv === 'object' && cv !== null && 'name' in cv) {
                  v = cv.name
                } else {
                  v = cv
                }

                resolvedTrackingPropertyObject = this.getResolvedTrackingPropertyObject(
                  key,
                  v,
                  config
                )
                return resolvedTrackingPropertyObject.value
              })

            if (mappedValues.length) {
              changedParams.push({
                [resolvedTrackingPropertyObject.property]: mappedValues.join(
                  ', '
                )
              })
            }
          } else if (
            typeof initialValue === 'object' &&
            initialValue !== null &&
            initialValue.min === null &&
            initialValue.max === null &&
            (![null, undefined].includes(currentValue?.min) ||
              ![null, undefined].includes(currentValue?.max))
          ) {
            const getRangeOptions = property => {
              const optionsByPropertyMap = {
                consumerPriceGrossRange: this.priceRangeOptions,
                financingMonthlyInstallmentRange: this
                  .financingRateRangeOptions,
                leasingGrossRateRange: this.leasingRateRangeOptions
              }

              return optionsByPropertyMap[property] || null
            }

            const rangeOptions = getRangeOptions(key)

            if (currentValue.min !== null) {
              const resolvedTrackingPropertyObject = this.getResolvedTrackingPropertyObject(
                key,
                currentValue.min,
                config.min
              )

              changedParams.push({
                [resolvedTrackingPropertyObject.property]:
                  resolvedTrackingPropertyObject.value
              })
            }
            if (currentValue.max !== null) {
              const resolvedTrackingPropertyObject = this.getResolvedTrackingPropertyObject(
                key,
                currentValue.max === rangeOptions?.max
                  ? 'unlimited'
                  : currentValue.max,
                config.max
              )

              changedParams.push({
                [resolvedTrackingPropertyObject.property]:
                  resolvedTrackingPropertyObject.value
              })
            }
          }

          const getFinancingType = property => {
            const map = {
              consumerPriceGrossRange: 'purchase',
              financingMonthlyInstallmentRange: 'financing',
              leasingGrossRateRange: 'leasing'
            }

            return map[property] !== undefined ? map[property] : null
          }

          let configs = [config]

          if (config.min !== undefined && config.max !== undefined) {
            configs = [config.min, config.max]
          }

          // only extend by configs of keys that are part of changed params
          configs = configs.filter(
            config => changedParams.find(p => p[config.property]) !== undefined
          )

          configs.forEach(config => {
            if (Array.isArray(config.extendBy)) {
              const propertiesToResolveByConfig = {
                financingType: getFinancingType(key)
              }

              config.extendBy.forEach(property => {
                if (propertiesToResolveByConfig[property] !== undefined) {
                  const value = propertiesToResolveByConfig[property]
                  const config = this.getTrackingPropertyConfig(property)

                  if (config !== null) {
                    const resolvedObject = this.getResolvedTrackingPropertyObject(
                      property,
                      value,
                      config
                    )

                    if (resolvedObject !== null) {
                      changedParams.push({
                        [resolvedObject.property]: resolvedObject.value
                      })
                    }
                  }
                } else if (property === 'currency') {
                  changedParams.push({
                    currency: 'EUR'
                  })
                }
              })
            }
          })
        }
      })

      if (changedParams.length) {
        const payload = {}

        changedParams.forEach(cp => {
          Object.assign(payload, cp)
        })

        this.gtm.trackEvent({
          name: 'search_final_no_car_available',
          payload
        })
      }
    },
    // TODO: similar to some sidebar methods
    getTrackingPropertyConfig(property) {
      let config = null

      if (typeof property === 'string') {
        if (trackingMapping[property] !== undefined) {
          config = trackingMapping[property]
        }
      } else if ('property' in property && 'subProperty' in property) {
        if (
          trackingMapping[property.property][property.subProperty] !== undefined
        ) {
          config = trackingMapping[property.property][property.subProperty]
        }
      }

      return config
    },

    getResolvedTrackingPropertyObject(property, value, config) {
      let resolvedObject = null

      let resolvedValue = null

      if (typeof config.value === 'function') {
        resolvedValue = config.value(value)
      } else if (typeof config.value === 'string') {
        resolvedValue = this.$t(
          config.value
            .replace('{vehicleClass}', this.params.vehicleClass[0])
            .replace('{property}', property)
            .replace('{value}', value)
        )
      }

      if (resolvedValue !== null) {
        resolvedObject = {
          property: config.property,
          value: resolvedValue
        }
      }

      return resolvedObject
    },

    trackSearchParam(property, value, isAdded) {
      const getTrackObject = (property, value) => {
        let payload = null
        let resolvedTrackingPropertyObject = null
        let extendedProperties = {}

        const config = this.getTrackingPropertyConfig(property)

        const propertyName =
          typeof property === 'string' ? property : property.property

        if (config !== null) {
          resolvedTrackingPropertyObject = this.getResolvedTrackingPropertyObject(
            propertyName,
            value,
            config
          )
        }

        if (resolvedTrackingPropertyObject !== null) {
          payload = {
            [resolvedTrackingPropertyObject.property]:
              resolvedTrackingPropertyObject.value
          }
        }

        const getFinancingType = property => {
          const map = {
            consumerPriceGrossRange: 'purchase',
            financingMonthlyInstallmentRange: 'financing',
            leasingGrossRateRange: 'leasing'
          }

          return map[property] !== undefined ? map[property] : null
        }

        if (Array.isArray(config.extendBy)) {
          const propertiesToResolveByConfig = {
            financingType: getFinancingType(propertyName)
          }

          config.extendBy.forEach(property => {
            if (propertiesToResolveByConfig[property] !== undefined) {
              const value = propertiesToResolveByConfig[property]
              const config = this.getTrackingPropertyConfig(property)

              if (config !== null) {
                const resolvedObject = this.getResolvedTrackingPropertyObject(
                  property,
                  value,
                  config
                )

                if (resolvedObject !== null) {
                  extendedProperties[resolvedObject.property] =
                    resolvedObject.value
                }
              }
            } else if (property === 'currency') {
              extendedProperties.currency = 'EUR'
            }
          })
        }

        return Object.assign({}, payload, extendedProperties)
      }

      const payload = getTrackObject(property, value)

      if (payload !== null) {
        this.gtm.trackEvent({
          name: isAdded ? 'search_criteria_added' : 'search_criteria_removed',
          payload
        })
      }
    }
  }
}
</script>
