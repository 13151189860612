export default {
  methods: {
    getFinancingTypeByParams(params) {
      let financingType = null

      const initialParams = this.getInitialSearchParams()

      const isSameRange = (a, b) => {
        return a.min === b.min && a.max === b.max
      }

      if (
        !isSameRange(
          initialParams.consumerPriceGrossRange,
          params.consumerPriceGrossRange
        )
      ) {
        financingType = 'purchase'
      } else if (
        !isSameRange(
          initialParams.financingMonthlyInstallmentRange,
          params.financingMonthlyInstallmentRange
        )
      ) {
        financingType = 'financing'
      } else if (
        !isSameRange(
          initialParams.leasingGrossRateRange,
          params.leasingGrossRateRange
        )
      ) {
        financingType = 'leasing'
      } else {
        financingType = 'purchase'
      }

      return financingType
    },
    getBudgetParamsByCurrentSelection(
      financingType,
      params,
      applyCleanReset = false
    ) {
      const initialParams = this.getInitialSearchParams()

      const resetParams = {
        consumerPriceGrossRange: initialParams.consumerPriceGrossRange,
        financingMonthlyInstallmentRange:
          initialParams.financingMonthlyInstallmentRange,
        leasingGrossRateRange: initialParams.leasingGrossRateRange,
        vatDeductible: initialParams.vatDeductible,
        isCommercialOffer: initialParams.isCommercialOffer
      }

      // set options based on given vehicle class value
      const priceRangeOptions = this.priceRangeOptionsByVehicleClass[
        params.vehicleClass[0]
      ]
      const financingRateRangeOptions = this
        .financingRateRangeOptionsByVehicleClass[params.vehicleClass[0]]
      const leasingRateRangeOptions = this
        .leasingRateRangeOptionsByVehicleClass[params.vehicleClass[0]]

      if (financingType === 'purchase') {
        let priceParams = {
          vatDeductible: applyCleanReset
            ? initialParams.vatDeductible
            : params.vatDeductible
        }

        const min =
          params.consumerPriceGrossRange.min === null
            ? priceRangeOptions.min
            : params.consumerPriceGrossRange.min

        const max =
          params.consumerPriceGrossRange.max === null
            ? priceRangeOptions.max
            : params.consumerPriceGrossRange.max

        if (min !== priceRangeOptions.min || max !== priceRangeOptions.max) {
          priceParams = Object.assign(priceParams, {
            consumerPriceGrossRange: {
              min,
              max
            }
          })
        }
        params = Object.assign({}, params, resetParams, priceParams)
      } else if (financingType === 'financing') {
        params = Object.assign({}, params, resetParams, {
          financingMonthlyInstallmentRange: {
            min:
              params.financingMonthlyInstallmentRange.min === null
                ? financingRateRangeOptions.min
                : params.financingMonthlyInstallmentRange.min,
            max:
              params.financingMonthlyInstallmentRange.max === null
                ? financingRateRangeOptions.max
                : params.financingMonthlyInstallmentRange.max
          },
          vatDeductible: applyCleanReset
            ? initialParams.vatDeductible
            : params.vatDeductible
        })
      } else if (financingType === 'leasing') {
        params = Object.assign({}, params, resetParams, {
          leasingGrossRateRange: {
            min:
              params.leasingGrossRateRange.min === null
                ? leasingRateRangeOptions.min
                : params.leasingGrossRateRange.min,
            max:
              params.leasingGrossRateRange.max === null
                ? leasingRateRangeOptions.max
                : params.leasingGrossRateRange.max
          },
          vatDeductible: applyCleanReset
            ? initialParams.vatDeductible
            : params.vatDeductible,
          isCommercialOffer: params.isCommercialOffer
        })
      } else {
        params = Object.assign({}, params, resetParams)
      }

      return params
    }
  }
}
