import {
  formatPrice,
  formatMileage,
  formatNumber
} from '../../../utils/formatter'

const exteriorFeatureList = {
  Car: [
    'abs',
    'distanceWarningSystem',
    {
      key: 'bendingLightsType',
      value: 'ADAPTIVE_BENDING_LIGHTS'
    },
    'fourWheelDrive',
    'allSeasonTires',
    'heatedWindshield',
    'hillStartAssist',
    {
      key: 'headlightType',
      value: 'BI_XENON_HEADLIGHTS'
    },
    'glareFreeHighBeam',
    'roofRails',
    'electricTailgate',
    'immobilizer',
    'esp',
    'highBeamAssist',
    'speedLimiter',
    {
      key: 'bendingLightsType',
      value: 'BENDING_LIGHTS'
    },
    {
      key: 'headlightType',
      value: 'LASER_HEADLIGHTS'
    },
    {
      key: 'headlightType',
      value: 'LED_HEADLIGHTS'
    },
    {
      key: 'daytimeRunningLamps',
      value: 'LED_RUNNING_LIGHTS'
    },
    'alloyWheels',
    'lightSensor',
    'airSuspension',
    'nightVisionAssist',
    'frontFogLights',
    'collisionAvoidance',
    {
      key: 'breakdownService',
      value: 'EMERGENCY_WHEEL'
    },
    {
      key: 'breakdownService',
      value: 'REPAIR_KIT'
    },
    'panoramicGlassRoof',
    'automaticRainSensor',
    'tirePressureMonitoring',
    {
      key: 'breakdownService',
      value: 'SPARE_WHEEL'
    },
    'headlightWasherSystem',
    'sunroof',
    'keylessEntry',
    'powerAssistedSteering',
    'summerTires',
    'performanceHandlingSystem',
    'sportPackage',
    'laneDepartureWarning',
    'steelWheels',
    'startStopSystem',
    {
      key: 'daytimeRunningLamps',
      value: 'DAYTIME_RUNNING_LIGHTS'
    },
    'blindSpotMonitor',
    'tractionControlSystem',
    'trafficSignRecognition',
    'winterTires',
    {
      key: 'headlightType',
      value: 'XENON_HEADLIGHTS'
    },
    'centralLocking'
  ],
  Motorbike: [
    'abs',
    'electricStarter',
    'catalyticConverter',
    'kickstarter',
    'box',
    'windshield',
    'rollOverBar'
  ]
}

const interiorFeatureList = {
  Car: [
    'alarmSystem',
    'ambientLighting',
    'androidAuto',
    'carplay',
    'armRest',
    'heatedSteeringWheel',
    'disabledAccessible',
    'bluetooth',
    'onBoardComputer',
    'cdPlayer',
    'electricWindows',
    'electricExteriorMirrors',
    'electricAdjustableSeats',
    'electricBackseatAdjustment',
    'handsFreePhoneSystem',
    'cargoBarrier',
    'headUpDisplay',
    'wirelessCharging',
    'dimmingInteriorMirror',
    'isofix',
    'passengerSeatIsofixPoint',
    'leatherSteeringWheel',
    'lumbarSupport',
    'massageSeats',
    'fatigueWarningSystem',
    'multifunctionalWheel',
    'integratedMusicStreaming',
    'navigationSystem',
    'emergencyCallSystem',
    {
      key: 'radio',
      value: 'DAB_RADIO'
    },
    'smokersPackage',
    'paddleShifters',
    'ventilatedSeats',
    'electricHeatedSeats',
    'electricHeatedRearSeats',
    'skiBag',
    'soundSystem',
    'sportSeats',
    'voiceControl',
    'auxiliaryHeating',
    'touchscreen',
    {
      key: 'radio',
      value: 'TUNER'
    },
    'tv',
    'foldFlatPassengerSeat',
    'usb',
    'digitalCockpit',
    'winterPackage',
    'wifiHotspot'
  ],
  Motorbike: []
}

const getFeatureList = list => {
  return list.map(e => {
    if (typeof e === 'string') {
      return {
        key: 'flags',
        value: e
      }
    } else {
      return e
    }
  })
}

export const getExteriorFeatureListByVehicleClass = vehicleClass => {
  return getFeatureList(exteriorFeatureList[vehicleClass])
}

export const getInteriorFeatureListByVehicleClass = vehicleClass => {
  return getFeatureList(interiorFeatureList[vehicleClass])
}

const searchFormStructure = {
  Car: {
    basics: [
      {
        key: 'category',
        dependency: 'vehicleClass'
      },
      {
        key: 'condition',
        dependency: 'vehicleClass'
      },
      {
        key: 'usageType',
        dependency: 'vehicleClass'
      },
      {
        key: 'amUsageType',
        dependency: 'vehicleClass'
      },
      'usedCarSeal',
      {
        key: 'make',
        relatedFields: ['modelGroup']
      },
      {
        key: 'modelGroup',
        translation: false,
        relatedFields: ['model']
      },
      {
        key: 'model',
        translation: false
      },
      'fuel',
      {
        key: 'firstRegistration',
        format: value =>
          String(value).substring(4, 6) + '/' + String(value).substring(0, 4)
      },
      {
        key: 'mileage',
        unit: 'km',
        format: value => formatMileage(value)
      }
    ],
    budget: [
      'isCommercialOffer',
      {
        key: 'consumerPriceGrossRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      {
        key: 'financingMonthlyInstallmentRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      {
        key: 'leasingGrossRateRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      'vatDeductible'
    ],
    specifications: [
      'gearbox',
      {
        key: 'cubicCapacity',
        unit: 'cm³',
        format: value => formatNumber(value)
      },
      {
        key: 'power',
        unit: 'kW'
      }
    ],
    environment: [
      {
        key: 'consumptionCombined',
        unit: 'l/100 km'
      },
      {
        key: 'emissionClass',
        range: true
      },
      {
        key: 'flags',
        value: 'particulateFilterDiesel'
      },
      {
        key: 'emissionSticker',
        range: true
      }
    ],
    outside: [
      'exteriorColor',
      {
        key: 'flags',
        value: 'metallic'
      },
      'trailerCouplingType',
      'parkingAssistants',
      'speedControl'
    ].concat(getExteriorFeatureListByVehicleClass('Car')),
    inside: ['interiorColor', 'interiorType', 'airbag', 'climatisation'].concat(
      getInteriorFeatureListByVehicleClass('Car')
    ),
    advertisement: ['onlineRange']
  },
  Motorbike: {
    basics: [
      {
        key: 'category',
        dependency: 'vehicleClass'
      },
      {
        key: 'amUsageType',
        dependency: 'vehicleClass'
      },
      'usedCarSeal',
      {
        key: 'make',
        relatedFields: ['modelGroup']
      },
      {
        key: 'modelGroup',
        translation: false,
        relatedFields: ['model']
      },
      {
        key: 'model',
        translation: false
      },
      'fuel',
      {
        key: 'firstRegistration',
        format: value =>
          String(value).substring(4, 6) + '/' + String(value).substring(0, 4)
      },
      {
        key: 'mileage',
        unit: 'km',
        format: value => formatMileage(value)
      }
    ],
    budget: [
      'isCommercialOffer',
      {
        key: 'consumerPriceGrossRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      {
        key: 'financingMonthlyInstallmentRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      {
        key: 'leasingGrossRateRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      'vatDeductible'
    ],
    specifications: [
      'gearbox',
      {
        key: 'power',
        unit: 'kW'
      },
      'drivingMode',
      {
        key: 'cubicCapacity',
        unit: 'cm³',
        format: value => formatNumber(value)
      }
    ],
    environment: [],
    outside: [
      'exteriorColor',
      {
        key: 'flags',
        value: 'metallic'
      }
    ].concat(getExteriorFeatureListByVehicleClass('Motorbike')),
    inside: ['interiorColor', 'interiorType'].concat(
      getInteriorFeatureListByVehicleClass('Motorbike')
    ),
    advertisement: ['onlineRange']
  }
}

const sidebarSearchFormStructure = {
  Car: {
    category: [
      {
        key: 'category',
        dependency: 'vehicleClass'
      }
    ],
    fuel: ['fuel'],
    model: [
      {
        key: 'make',
        relatedFields: ['modelGroup']
      },
      {
        key: 'modelGroup',
        translation: false,
        relatedFields: ['model']
      }
    ],
    payment: [
      {
        key: 'consumerPriceGrossRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      {
        key: 'financingMonthlyInstallmentRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      {
        key: 'leasingGrossRateRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      'vatDeductible'
    ],
    leasingType: ['isCommercialOffer'],
    firstRegistration: [
      {
        key: 'firstRegistration',
        format: value =>
          String(value).substring(4, 6) + '/' + String(value).substring(0, 4)
      }
    ],
    mileage: [
      {
        key: 'mileage',
        unit: 'km',
        format: value => formatMileage(value)
      }
    ],
    technicalData: [
      'gearbox',
      {
        key: 'cubicCapacity',
        unit: 'cm³',
        format: value => formatNumber(value)
      },
      {
        key: 'power',
        unit: 'kW'
      }
    ],
    environment: [
      {
        key: 'consumptionCombined',
        unit: 'l/100 km'
      },
      {
        key: 'emissionClass',
        range: true
      },
      {
        key: 'flags',
        value: 'particulateFilterDiesel'
      },
      {
        key: 'emissionSticker',
        range: true
      }
    ],
    outside: [
      'exteriorColor',
      {
        key: 'flags',
        value: 'metallic'
      },
      'trailerCouplingType',
      'parkingAssistants',
      'speedControl'
    ].concat(getExteriorFeatureListByVehicleClass('Car')),
    inside: ['interiorColor', 'interiorType', 'airbag', 'climatisation'].concat(
      getInteriorFeatureListByVehicleClass('Car')
    ),
    inserat: ['onlineRange'],
    alwaysVisibleContent: [
      {
        key: 'condition',
        dependency: 'vehicleClass'
      },
      {
        key: 'usageType',
        dependency: 'vehicleClass'
      },
      {
        key: 'amUsageType',
        dependency: 'vehicleClass'
      },
      'usedCarSeal'
    ]
  },
  Motorbike: {
    category: [
      {
        key: 'category',
        dependency: 'vehicleClass'
      }
    ],
    fuel: ['fuel'],
    model: [
      {
        key: 'make',
        relatedFields: ['modelGroup']
      },
      {
        key: 'modelGroup',
        translation: false,
        relatedFields: ['model']
      }
    ],
    payment: [
      {
        key: 'consumerPriceGrossRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      {
        key: 'financingMonthlyInstallmentRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      {
        key: 'leasingGrossRateRange',
        unit: '€',
        format: value => formatPrice(value, false, false)
      },
      'vatDeductible'
    ],
    leasingType: ['isCommercialOffer'],
    firstRegistration: [
      {
        key: 'firstRegistration',
        format: value =>
          String(value).substring(4, 6) + '/' + String(value).substring(0, 4)
      }
    ],
    mileage: [
      {
        key: 'mileage',
        unit: 'km',
        format: value => formatMileage(value)
      }
    ],
    technicalData: [
      'drivingMode',
      'gearbox',
      {
        key: 'cubicCapacity',
        unit: 'cm³',
        format: value => formatNumber(value)
      },
      {
        key: 'power',
        unit: 'kW'
      }
    ],
    environment: [],
    outside: [
      'exteriorColor',
      {
        key: 'flags',
        value: 'metallic'
      }
    ].concat(getExteriorFeatureListByVehicleClass('Motorbike')),
    inside: ['interiorColor', 'interiorType'].concat(
      getInteriorFeatureListByVehicleClass('Motorbike')
    ),
    inserat: ['onlineRange'],
    alwaysVisibleContent: [
      {
        key: 'amUsageType',
        dependency: 'vehicleClass'
      },
      'usedCarSeal'
    ]
  }
}

export const getSearchFormStructureByVehicleClass = (
  vehicleClass,
  sidebar = false
) => {
  if (sidebar === true) {
    return sidebarSearchFormStructure[vehicleClass]
  } else {
    return searchFormStructure[vehicleClass]
  }
}

export default {
  getExteriorFeatureListByVehicleClass,
  getInteriorFeatureListByVehicleClass,
  getSearchFormStructureByVehicleClass
}
