import { formatMileage } from '../../../utils/formatter'

export default {
  currency: {
    // virtual field that is not part of the search form but needs to be tracked within monetary values
    property: 'currency',
    value: () => 'EUR'
  },
  vehicleClass: {
    property: 'search_vehicle_type',
    value: 'tracking.vehicleClass.{value}'
  },
  amUsageType: {
    property: 'search_vehicle_am_usage_type',
    value: 'vehicleClass.{vehicleClass}.{property}{value}'
  },
  usedCarSeal: {
    property: 'search_used_car_seal',
    value: 'vehicle.{property}{value}'
  },
  category: {
    property: 'search_vehicle_subtype',
    value: 'vehicleClass.{vehicleClass}.{property}{value}'
  },
  fuel: {
    property: 'search_fuel',
    value: 'vehicle.{property}{value}'
  },
  make: {
    property: 'search_brand',
    value: 'vehicle.{property}{value}'
  },
  modelGroup: {
    property: 'search_modelGroup',
    value: value => value
  },
  financingType: {
    // virtual key, not yet (!) part of our graphQL model but is already search form element
    property: 'search_payment_type',
    value: 'tracking.paymentMethods.{value}'
  },
  consumerPriceGrossRange: {
    min: {
      property: 'search_budget_range_min',
      value: v => v,
      extendBy: ['currency']
    },
    max: {
      property: 'search_budget_range_max',
      value: v => v,
      extendBy: ['currency']
    }
  },
  financingMonthlyInstallmentRange: {
    min: {
      property: 'search_monthly_budget_range_min',
      value: v => v,
      extendBy: ['currency', 'financingType']
    },
    max: {
      property: 'search_monthly_budget_range_max',
      value: v => v,
      extendBy: ['currency', 'financingType']
    }
  },
  leasingGrossRateRange: {
    min: {
      property: 'search_monthly_budget_range_min',
      value: v => v,
      extendBy: ['currency', 'financingType']
    },
    max: {
      property: 'search_monthly_budget_range_max',
      value: v => v,
      extendBy: ['currency', 'financingType']
    }
  },
  vatDeductible: {
    property: 'search_payment_tax',
    value: 'vehicle.{property}true'
  },
  firstRegistration: {
    min: {
      property: 'search_first_registration_min',
      value: v => {
        let retValue = null

        if (typeof v === 'number') {
          const month = v.toString().slice(4, 6)
          retValue = month + '/' + v.toString().slice(0, -2)
        }

        return retValue
      }
    },
    max: {
      property: 'search_first_registration_max',
      value: v => {
        let retValue = null

        if (typeof v === 'number') {
          const month = v.toString().slice(4, 6)
          retValue = month + '/' + v.toString().slice(0, -2)
        }

        return retValue
      }
    }
  },
  mileage: {
    min: {
      property: 'search_mileage_min',
      value: v => formatMileage(v)
    },
    max: {
      property: 'search_mileage_max',
      value: v => formatMileage(v)
    }
  },
  gearbox: {
    property: 'search_gearbox',
    value: 'vehicle.{property}{value}'
  },
  cubicCapacity: {
    min: {
      property: 'search_cubic_capacity_min',
      value: v => v.toString()
    },
    max: {
      property: 'search_cubic_capacity_max',
      value: v => v.toString()
    }
  },
  powerType: {
    property: 'search_power_type',
    value: v => v
  },
  power: {
    min: {
      property: 'search_power_kw_min',
      value: v => v.toString()
    },
    max: {
      property: 'search_power_kw_max',
      value: v => v.toString()
    }
  },
  consumptionCombined: {
    property: 'search_fuel_consumption',
    value: v => v.toString()
  },
  emissionClass: {
    property: 'search_emission_class',
    value: 'vehicle.{property}{value}'
  },
  flags: {
    property: 'search_flag',
    value: 'vehicle.{value}'
  },
  emissionSticker: {
    property: 'search_efficiency_class',
    value: 'vehicle.{property}{value}'
  },
  bendingLightsType: {
    property: 'search_bending_lights_type',
    value: 'vehicle.{property}{value}'
  },
  headlightType: {
    property: 'search_headlight_type',
    value: 'vehicle.{property}{value}'
  },
  search_daytime_running_lamps: {
    property: 'daytimeRunningLamps',
    value: 'vehicle.{property}{value}'
  },
  breakdownService: {
    property: 'search_breakdown_service',
    value: 'vehicle.{property}{value}'
  },
  radio: {
    property: 'search_radio',
    value: 'vehicle.{property}{value}'
  },
  exteriorColor: {
    property: 'search_color_exterior',
    value: 'vehicle.{property}{value}'
  },
  trailerCouplingType: {
    property: 'search_trailer_coupling_type',
    value: 'vehicle.{property}{value}'
  },
  parkingAssistants: {
    property: 'search_parking_assistance',
    value: 'vehicle.{property}{value}'
  },
  speedControl: {
    property: 'search_speed_control',
    value: 'vehicle.{property}{value}'
  },
  interiorColor: {
    property: 'search_color_interior',
    value: 'vehicle.{property}{value}'
  },
  interiorType: {
    property: 'search_interior_type',
    value: 'vehicle.{property}{value}'
  },
  airbag: {
    property: 'search_airbag',
    value: 'vehicle.{property}{value}'
  },
  onlineRange: {
    property: 'search_online_range',
    value: 'vehicle.{property}{value}'
  },
  drivingMode: {
    property: 'search_driving_mode',
    value: 'vehicle.{property}{value}'
  },
  climatisation: {
    property: 'search_climatisation',
    value: 'vehicle.{property}{value}'
  }
}
